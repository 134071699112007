import React, { useState } from "react";
interface FormInvities {
  disabled: boolean;
  readOnly?: boolean;
  messageStyle?: string;
  onchange?: any;
  sliderClass?: boolean;
}
const FormInvities: React.FC<FormInvities> = ({
  disabled,
  readOnly,
  messageStyle,
  onchange,
  sliderClass,
}) => {
  const handleChange = (data: any) => {
    // @ts-ignore
    onchange(data.target.value);
  };
  return (
    <div className="w-full flex  flex-col ">
      <div className="text-[0.8rem] text-left">
        <p className="text-[0.75rem] text-[#8B8B8B] font-[200]">
          {readOnly ? "Invitees will see like this" : ""}
        </p>
        <p className={`text-[#8B8B8B] font-[200] ${messageStyle}`}>
          Number of people attending, including you? *
        </p>
      </div>
      <div className="w-full flex flex-col items-center justify-center my-3">
        <input
          className={sliderClass ? "sliderClass" : "invitiesSlider"}
          max={8}
          min={1}
          step={1}
          defaultValue={1}
          type="range"
          name=""
          id=""
          data-pr-tooltip=""
          disabled={disabled}
          onChange={(e: any) => {
            handleChange(e);
          }}
        />
        <div className="flex w-[50%] justify-between text-[0.8rem] text-[#666666] mt-2">
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>
        </div>
      </div>
    </div>
  );
};

export default FormInvities;
