export const FormData = [
  {
    name: "category",
    disabled: false,
    required: false,
    options: [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
    ],
    type: "dropdown",
    label: "Pick an Event Category",
    placeholder: "Please select your category",
  },
  {
    name: "subCategory",
    value: "",
    type: "dropdown",
    options: [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
    ],
    required: false,
    disabled: false,
    label: "Sub Category (Optional)",
    placeholder: "Please select your sub category",
  },
  {
    name: "title",
    value: "",
    type: "text",
    required: "Please Enter the Title",
    disabled: false,
    label: "Give a Nice Title",
    placeholder: "Example: Avinash 1st Birthday",
  },
  {
    name: "venueName",
    value: "",
    type: "text",
    required: false,
    disabled: false,
    label: "Venue Name (Optional)",
    placeholder: "Grand Galla",
  },
  {
    name: "venueAddress",
    value: "",
    type: "text",
    required: "Please Enter the Venue Address",
    disabled: false,
    label: "Address",
    pickMyLocation: true,
    locationMessage: "Pick current location",
    placeholder: "Enter Venue Address",
  },
  {
    name: "calander",
    value: "",
    type: "date",
    required: false,
    disabled: false,
    label: "Start Date",
    startName: "startDate",
    endDate: "endDate",
    startLabel: "Start Date",
    endLabel: "End Date",
    placeholder: "Choose date from calender",
  },
  {
    name: "time",
    value: "",
    type: "time",
    required: false,
    disabled: false,
    label: "Time",
    startName: "startTime",
    endDate: "endTime",
    placeholder: "",
  },
  {
    name: "invities",
    value: "",
    type: "slider",
    required: false,
    disabled: false,
    label: "",
    placeholder: "",
  },
];
