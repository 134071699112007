import {
  VERFY_SIGUP_OTP_REQUEST,
  VERFY_SIGUP_OTP_SUCCESS,
  VERFY_SIGUP_OTP_FAILURE,
  VERFY_SIGUP_OTP_EMPTY,
} from "../../../Utils/Constant";

export const verifyRegisterOtpRequest = (payload: object) => {
  return {
    type: VERFY_SIGUP_OTP_REQUEST,
    payload: payload,
  };
};

export const verifyRegisterOtpSuccess = (data: object) => {
  return {
    type: VERFY_SIGUP_OTP_SUCCESS,
    payload: data,
  };
};

export const verifyRegisterOtpFailure = (error: object) => {
  return {
    type: VERFY_SIGUP_OTP_FAILURE,
    payload: error,
  };
};

export const makeRegisterEmpty = (error: object) => {
  return {
    type: VERFY_SIGUP_OTP_EMPTY,
    payload: error,
  };
};
