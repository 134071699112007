import React, { useState } from "react";
import { countries } from "../../Utils/dummyStorage/data";
import { GoChevronDown } from "react-icons/go";

interface InputSuggestionProps {
  lable?: string;
  mainStyle?: string;
  disable?: boolean;
  type?: string;
  onChange: (data: any) => void;
  lableStyle?: string;
  placeHolder?: string;
  inputStyle?: string;
  err?: boolean;
  errMsg?: string;
  value?: any;
}

const InputSuggestion: React.FC<InputSuggestionProps> = ({
  lable,
  mainStyle,
  disable,
  type,
  onChange,
  lableStyle,
  placeHolder,
  inputStyle,
  err,
  value,
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState<any>([]);
  const [current, setCurrent] = useState<string>("India");
  const [showAll, setShowAll] = useState<boolean>(false); // State to toggle showing all suggestions

  const handleInputChange = (inputValue: string) => {
    setCurrent(inputValue);
    const filtered = countries.filter((country) =>
      country.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredSuggestions(filtered);
  };

  const handleSuggestionClick = (suggestion: any) => {
    setCurrent(suggestion.name);
    onChange(suggestion);
    setFilteredSuggestions([]);
  };

  const handleArrowClick = () => {
    setShowAll(!showAll);
  };

  return (
    <div className={`${mainStyle} flex flex-col relative`}>
      {lable && <p className={lableStyle}>{lable}</p>}
      <input
        type={type || "text"}
        placeholder={placeHolder || ""}
        disabled={disable}
        value={current}
        className={`${
          inputStyle || ""
        } px-2 py-2 rounded-lg shadow placeholder:text-[0.8rem] ${
          err ? "border border-red-400" : "border border-[#cccccc]"
        } text-[0.8rem] h-[2.7rem]`}
        onChange={(e) => handleInputChange(e.target.value)}
      />
      {(filteredSuggestions.length > 0 || showAll) && (
        <ul className="absolute bg-white border border-gray-300 w-full z-10 top-16 p-1 rounded-lg max-h-[10.5rem] overflow-y-auto">
          {showAll
            ? countries.map((suggestion: any, index: number) => (
                <li
                  key={index}
                  className="px-2 py-1 cursor-pointer text-[0.8rem] hover:bg-gray-100"
                  onClick={() => {
                    handleSuggestionClick(suggestion);
                    setShowAll(false);
                  }}
                >
                  {suggestion.name}
                </li>
              ))
            : filteredSuggestions.map((suggestion: any, index: number) => (
                <li
                  key={index}
                  className="px-2 py-1 cursor-pointer text-[0.8rem] hover:bg-gray-100"
                  onClick={() => {
                    handleSuggestionClick(suggestion);
                    setShowAll(false);
                  }}
                >
                  {suggestion.name}
                </li>
              ))}
        </ul>
      )}

      <div
        className="absolute right-3 top-8 cursor-pointer font-thin text-[1.3rem]"
        onClick={handleArrowClick}
      >
        {showAll ? <GoChevronDown /> : <GoChevronDown />}
      </div>
    </div>
  );
};

export default InputSuggestion;
