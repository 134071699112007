import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API } from "../../../Services/API/ApiInstance";
import { getContactFailure, getContactSuccess } from "../../Actions";

function* getContactSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  const pageNo = data.pageNo;
  const pageSize = data.pageSize;
  const searchBy = data.searchBy;
  const searchValue = data.searchValue;

  function buildURL(
    pageNo: number | undefined,
    pageSize: number | undefined,
    searchBy: string | undefined,
    searchValue: string | undefined,
  ) {
    let url = `${authApi.GET_CONTACT}`;

    if (pageNo || pageSize || searchBy || searchValue) {
      url += "?";

      if (pageNo) {
        url += "pageNo=" + encodeURIComponent(pageNo) + "&";
      }
      if (pageSize) {
        url += "pageSize=" + encodeURIComponent(pageSize) + "&";
      }
      if (searchBy) {
        url += "searchBy=" + encodeURIComponent(searchBy)  + "&";
      }
      if (searchValue) {
        url +="searchValue=" + encodeURIComponent(searchValue);
      }
    }

    return url;
  }

  const newurl = buildURL(pageNo, pageSize, searchBy, searchValue);

  const path = newurl;

  try {
    const res = yield call(GET_API, path);
    yield put(getContactSuccess(res.data));
  } catch (error: any) {
    if (error?.response?.data?.statusCode === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    }
    yield put(getContactFailure(error));
  }
}

export default getContactSaga;
