import {
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_UP_EMPTY,
} from "../../../Utils/Constant";

export const signUprequest = (payload: object) => {
  return {
    type: SIGN_UP_REQUEST,
    payload: payload,
  };
};

export const signUpSuccess = (data: object) => {
  return {
    type: SIGN_UP_SUCCESS,
    payload: data,
  };
};

export const signUpFailure = (error: object) => {
  return {
    type: SIGN_UP_FAILURE,
    payload: error,
  };
};

export const makeSignUpEmpty = () => {
  return {
    type: SIGN_UP_EMPTY,
  };
};
