import React from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";

interface OAuthLoginProps {
  setAccessToken: any;
}

const OAuthLogin: React.FC<OAuthLoginProps> = ({ setAccessToken }) => {
  const onLoginSuccess = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ("accessToken" in response) {
      setAccessToken(response.accessToken);
    } else {
      console.error("Login Failed:", response);
    }
  };

  const onLoginFailure = (error: any) => {
    console.error("Login Failed:", error);
  };

  return (
    <span>
      <GoogleLogin
        clientId="956209229820-kql53hoebvr8islknh8hkluqtsimo6tj.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={onLoginSuccess}
        onFailure={onLoginFailure}
        cookiePolicy="single_host_origin"
        prompt="select_account"
      />
    </span>
  );
};

export default OAuthLogin;
