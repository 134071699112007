import {
  INVITEE_RESPONSE_PAGE_REQUEST,
  INVITEE_RESPONSE_PAGE_SUCCESS,
  INVITEE_RESPONSE_PAGE_FAILURE,
} from "../../../Utils/Constant";

export const inviitationResponseRequest = (data: object) => {
  return {
    type: INVITEE_RESPONSE_PAGE_REQUEST,
    payload: data,
  };
};

export const inviitationResponseSuccess = (data: object) => {
  return {
    type: INVITEE_RESPONSE_PAGE_SUCCESS,
    payload: data,
  };
};

export const inviitationResponseFailure = (error: object) => {
  return {
    type: INVITEE_RESPONSE_PAGE_FAILURE,
    payload: error,
  };
};
