import React from "react";
import OtpInput from "react-otp-input";

interface OTPInputProps {
  value: string;
  onChange: (otp: any) => void;
  numInputs: number;
  inputStyle: string;
  containerStyle: string;
}

const OTPInputComponent: React.FC<OTPInputProps> = ({
  value,
  onChange,
  numInputs,
  inputStyle,
  containerStyle,
}) => {
  const handleChange = (otp: string) => {
    onChange(otp);
  };

  const renderInput = (inputProps: any, index: number) => {
    return (
      <input
        {...inputProps}
        key={index}
        className={inputStyle}
        style={{
          width: "2.5rem",
          height: "2.5rem",
          marginRight: "0.7rem",
          userSelect: "none",
        }}
      />
    );
  };

  return (
    <OtpInput
      value={value}
      onChange={handleChange}
      numInputs={numInputs}
      inputType="number"
      containerStyle={containerStyle}
      renderInput={renderInput} // Pass the renderInput function
    />
  );
};

export default OTPInputComponent;
