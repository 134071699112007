import React from "react";
import FormHeader from "../../Component/EventForm/FormHeader";
import { IoIosArrowForward } from "react-icons/io";
import { VenderList } from "../../Utils/dummyStorage/data";
import { useNavigate } from "react-router-dom";

const Vender = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      <div className="h-full flex flex-col items-center justify-center w-full text-center">
        <FormHeader header={"Vender"} message={""} onBack={handleBack} />
        <div className="h-full w-full flex justify-center my-6 overflow-scroll">
          <div className="md:w-3/4 w-[90%] lg:w-3/4 xl:w-1/2 h-max md:px-16 max-w-[40rem] px-6 bg-white flex justify-center flex-col items-center pb-16">
            <div className="w-full text-center py-6 mx-2 text-[#4d4d4d] text-[0.8rem]">
              Need these services?
            </div>
            {VenderList.map((item, index) => (
              <div
                key={index}
                className="w-3/4 border border-gray-200 rounded-lg p-4 my-1.5 items-center flex justify-between cursor-pointer"
                onClick={() => {
                  window.open(
                    `https://www.google.com/search?q=${item.label.replaceAll(
                      " ",
                      "+"
                    )} near me`,
                    "_blank"
                  );
                }}
              >
                <p className="text-[0.8rem]">{item.label}</p>
                <p>
                  <IoIosArrowForward />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vender;
