import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API } from "../../../Services/API/ApiInstance";
import { getEventIDFailure, getEventIDSuccess } from "../../Actions";

function* getEventIDSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(
      GET_API,
      authApi.GET_EVENT_BY_ID + data
    );
    yield put(getEventIDSuccess(res));
  } catch (error: any) {
    if (error?.response?.data?.statusCode === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    }
    // toast.error(error?.response?.data?.message);
    yield put(getEventIDFailure(error));
  }
}

export default getEventIDSaga;
