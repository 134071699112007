import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API } from "../../../Services/API/ApiInstance";
import { getMainCategoryfailure, getMainCategorySuccess } from "../../Actions";

function* mainCategorySaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(GET_API, authApi.EVENTS_CATEGORY, data);
    yield put(getMainCategorySuccess(res.data));
  } catch (error: any) {
    // if (error?.response?.data?.statusCode === 401) {
    //   localStorage.removeItem("token");
    //   sessionStorage.removeItem("token");
    // }
    // toast.error(error?.response?.data?.message);
    yield put(getMainCategoryfailure(error));
  }
}

export default mainCategorySaga;
