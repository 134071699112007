import React, { ReactChild } from "react";
import AuthCardHeader from "./AuthCardHeader";
import { Card } from "primereact/card";

interface AuthCard {
  headerLable: string;
  handleBack: () => void;
  children: ReactChild;
  backArrow: boolean;
  closeIcon: boolean;
  onClose: () => void;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
const AuthCard: React.FC<AuthCard> = ({
  headerLable,
  handleBack,
  children,
  backArrow,
  closeIcon,
  onClose,
}) => {
  return (
    <div className={`flex justify-center w-full ${headerLable === "Log In" ? 'mt-24' : 'mt-10' } relative h-full`}>
      <Card
        title={
          <AuthCardHeader
            backArrow={backArrow}
            headerLable={headerLable}
            onBack={() => handleBack()}
            closeIcon={closeIcon}
            onClose={onClose}
          />
        }
        className="w-[85%] md:max-w-[40rem] md:min-w-[40rem] px-4 h-max rounded-lg shadow-lg"
      >
        {children}
      </Card>
    </div>
  );
};

export default AuthCard;
