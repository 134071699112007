import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { POST_API } from "../../../Services/API/ApiInstance";
import {
  updateCreditPlanFailure,
  updateCreditPlanSuccess,
} from "../../Actions";

function* updateCreditPlanSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    toast.loading("Adding Credits");
    const res = yield call(POST_API, authApi.UPDATE_PAYMENT, data);
    toast.dismiss();
    toast.success(res.message);
    yield put(updateCreditPlanSuccess(res.data));
  } catch (error: any) {
    if (error?.response?.data?.statusCode === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    }
    toast.dismiss();
    toast.error(error?.response?.data?.message);
    yield put(updateCreditPlanFailure(error));
  }
}

export default updateCreditPlanSaga;
