export function convertDate(date: Date | null | undefined): string | null {
  if (!date) {
    return null;
  }

  // Convert to local timezone
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  // Formatting the resulting date to "YYYY-MM-DD" format
  const convertedDate: string = localDate.toISOString().split("T")[0];

  return convertedDate;
}

export function convertStringToDateObject(dateString: string): Date | null {
  if (!dateString) {
    return null;
  }
  // Split the date string into year, month, and day parts
  const [yearStr, monthStr, dayStr] = dateString.split("-");
  const year = parseInt(yearStr, 10);
  const month = parseInt(monthStr, 10) - 1; // Month is zero-based
  const day = parseInt(dayStr, 10);

  // Create a new Date object
  const dateObject = new Date(year, month, day);

  return dateObject;
}

export const formatDate = (dateString: string) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  // @ts-ignore
  const formattedDate = new Date(dateString).toLocaleDateString("en-GB", options);
  const dayIndex = formattedDate.indexOf(" ");
  const monthIndex = formattedDate.lastIndexOf(" ");
  return (
    formattedDate.slice(0, dayIndex) + "," +
    formattedDate.slice(dayIndex, monthIndex) + "," +
    formattedDate.slice(monthIndex)
  );
};
