import {
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
} from "../../../Utils/Constant";

export const updateCreditPlanRequest = (data: object) => {
  return {
    type: UPDATE_PAYMENT_REQUEST,
    payload: data,
  };
};

export const updateCreditPlanSuccess = (data: object) => {
  return {
    type: UPDATE_PAYMENT_SUCCESS,
    payload: data,
  };
};

export const updateCreditPlanFailure = (error: object) => {
  return {
    type: UPDATE_PAYMENT_FAILURE,
    payload: error,
  };
};
