import { useNavigate } from "react-router-dom";
import { invitationSuccess } from "../../Asset";
import Button from "../AuthButton/Button";

const InvitationSuccess: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-full flex justify-center my-6 overflow-scroll">
          <div className="md:w-3/4 w-full lg:w-3/4 xl:w-1/2 h-max md:px-16 max-w-[40rem] px-6 flex justify-center flex-col items-center pb-16">
            <div className="w-full py-6 mx-2 text-[#4d4d4d] font-[200] text-[0.8rem] border-b"></div>
            <img src={invitationSuccess} alt="" className="w-24 h-24" />
            <span className="w-full text-[#024773] text-md mt-8">
              Yahoo! You Invitation is on the way...
            </span>
            <div className="w-1/2 text-[#024773] text-md">
              Good job!{" "}
              <div className="text-[#024773] text-md mt-4 w-max-content whitespace-nowrap">
                Now relax and look for responses on
              </div>
              <span className="block">WhatsApp soon</span>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-full min-w-72 flex justify-center flex-col">
        <div className="flex justify-center w-full">
          <Button
            lable={"Home"}
            gredient={true}
            mainStyle="w-full md:w-[40%] max-w-[15rem]"
            buttonClick={() => navigate("/")}
          />
        </div>
      </div>
    </div>
  );
};

export default InvitationSuccess;
