import {
  GET_GOOGLE_CONTACT_REQUEST,
  GET_GOOGLE_CONTACT_SUCCESS,
  GET_GOOGLE_CONTACT_FAILURE,
} from "../../../Utils/Constant";

export const getContactRequest = (data: object) => {
  return {
    type: GET_GOOGLE_CONTACT_REQUEST,
    payload: data,
  };
};

export const getContactSuccess = (data: object) => {
  return {
    type: GET_GOOGLE_CONTACT_SUCCESS,
    payload: data,
  };
};

export const getContactFailure = (error: object) => {
  return {
    type: GET_GOOGLE_CONTACT_FAILURE,
    payload: error,
  };
};
