import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Button from "../Component/AuthButton/Button";

interface Contact {
  fullName: string;
  mobileNumber: string;
  url: string;
}

interface SelectedContactProps {
  open: boolean;
  onClose: () => void;
  contactsData: Contact[];
  selectedContacts: Contact[];
  setSelectedContacts: any;
}

const SelectedContact: React.FC<SelectedContactProps> = ({
  open,
  onClose,
  contactsData,
  selectedContacts,
  setSelectedContacts,
}) => {
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [tempContact, setTempContact] = useState<Array<Contact>>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCount, setSelectedCount] = useState(selectedContacts.length);

  useEffect(() => {
    if (selectedContacts.length === 0) {
      setSelectAll(false);
      setSelectedCount(selectedContacts.length);
    } else if (selectedContacts.length === contactsData.length) {
      setSelectAll(true);
      setSelectedCount(selectedContacts.length);
    } else {
      setSelectAll(false);
      setSelectedCount(selectedContacts.length);
    }
  }, [selectedContacts, contactsData]);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setSelectedCount(0);
      setSelectedIndex([]);
      setTempContact([]);
    } else {
      setSelectedCount(contactsData.length);
      setTempContact(contactsData);
    }
  };

  const toggleSelectContact = (contact: Contact, index: number) => {
    const isSelected = tempContact.some(
      (selectedContact) =>
        selectedContact.fullName === contact.fullName &&
        selectedContact.mobileNumber === contact.mobileNumber
    );
    if (isSelected) {
      setTempContact((prev) => {
        return prev.filter(
          (selectedContact) =>
            selectedContact.fullName !== contact.fullName ||
            selectedContact.mobileNumber !== contact.mobileNumber
        );
      });
      const updatedContacts = selectedContacts.filter(
        (selectedContact) =>
          selectedContact.fullName !== contact.fullName ||
          selectedContact.mobileNumber !== contact.mobileNumber
      );
      setSelectedCount(updatedContacts.length);
    } else {
      // @ts-ignore
      setSelectedIndex((prev) => {
        return [...prev, index];
      });
      setTempContact((prev) => {
        return [...prev, contact];
      });

      setSelectedCount(selectedCount + 1);
    }
  };

  const handleSelect = () => {
    // TODO: add logic for finlize contact list
    setSelectedContacts(tempContact);
    onClose();
  };

  if (!open || !contactsData) return null;

  return (
    <div className="fixed md:pl-[20vw] w-screen inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-30"></div>
      <div
        id="contact-modal"
        className="bg-white rounded-lg p-6 md:w-[30rem] w-[90%] min-w-60 z-50 relative mt-18 overflow-hidden"
      >
        <button
          className="absolute top-4 right-4 text-gred-500 hover:text-gray-600"
          onClick={onClose}
        >
          <AiOutlineClose size={20} />
        </button>
        <h2 className="text-md font-bold">Choose a contact</h2>
        <div className="w-full flex gap-1 flex-col justify-center overflow-scroll">
          <div className="flex my-2 border-b borde-grey-500 py-2 align-middle items-center cursor-pointer">
            <input
              type="checkbox"
              name=""
              id=""
              checked={selectAll}
              onChange={toggleSelectAll}
            />
            <div className="text-sm mx-2">
              Select All ({selectedCount}/{contactsData.length})
            </div>
          </div>
          <div className="h-[300px] overflow-x-auto">
            {contactsData.map((contact: Contact, index: number) => (
              <div className="flex gap-1 w-full" key={index}>
                <input
                  key={index}
                  type="checkbox"
                  name=""
                  id=""
                  // @ts-ignore
                  checked={selectedIndex.includes(index) || selectAll}
                  onChange={() => toggleSelectContact(contact, index)}
                />
                <div className="cursor-pointer w-full m-1 hover:bg-gray-100 flex items-center justify-between  border p-1 rounded-lg border-gray-200">
                  <div className="flex items-center">
                    <div className="rounded-full mx-2 overflow-hidden h-[2.30rem] w-[2.30rem]">
                      <img
                        className="h-full w-full object-cover"
                        src={contact.url}
                        alt={contact.fullName}
                      />
                    </div>
                    <div className="mx-2">
                      <div className="text-left text-sm">
                        {contact.fullName}
                      </div>
                      <div className="text-sm text-gray-500 text-left">
                        {contact.mobileNumber}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Button lable={"Select"} gredient={true} buttonClick={handleSelect} />
        </div>
      </div>
    </div>
  );
};

export default SelectedContact;

// import React, { useEffect } from "react";
// import { AiOutlineClose } from "react-icons/ai";
// import Button from "../Component/AuthButton/Button";
// interface Contact {
//   fullName: string;
//   mobileNumber: string;
// }

// interface SelectedContactProps {
//   selectedContactIcon: boolean;
//   open: boolean;
//   onClose: () => void;
//   handleSelectedContact1: (
//     fullName: string,
//     number: string,
//     url: string
//   ) => void;
//   handleSelectedContact2: (
//     fullName: string,
//     number: string,
//     url: string
//   ) => void;
//   contactsData: any;
//   selectedContacts: Contact[];
// }

// const SelectedContact: React.FC<SelectedContactProps> = ({
//   selectedContactIcon,
//   open,
//   onClose,
//   handleSelectedContact1,
//   handleSelectedContact2,
//   contactsData,
//   selectedContacts,
// }) => {
//   useEffect(() => {
//     const handleClickOutside = (event: MouseEvent) => {
//       if (!open) return;
//       const modal = document.getElementById("contact-modal");
//       if (modal && !modal.contains(event.target as Node)) {
//         onClose();
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [open, onClose]);

//   if (!open || !contactsData) return null;

//   // const contacts = contactsData.filter(
//   //   (contact: any) =>
//   //     !selectedContacts.some(
//   //       (selectedContact) =>
//   //         selectedContact.fullName === contact.fullName &&
//   //         selectedContact.mobileNumber === contact.mobileNumber
//   //     )
//   // );
//   const contacts = contactsData;

//   const handleContactSelect = (contact: any) => {
//     // if (!contact) return;

//     // if (!contact.fullName || !contact.mobileNumber) return;

//     if (selectedContactIcon) {
//       handleSelectedContact1(
//         contact.fullName,
//         contact.mobileNumber,
//         contact.url
//       );
//     } else {
//       handleSelectedContact2(
//         contact.fullName,
//         contact.mobileNumber,
//         contact.url
//       );
//     }
//     onClose();
//   };

//   return (
//     <div className="fixed md:pl-[20vw] w-screen inset-0 flex items-center justify-center z-50">
//       <div className="fixed inset-0 bg-black opacity-30"></div>
//       <div
//         id="contact-modal"
//         className="bg-white rounded-lg p-6 md:w-[30rem] w-[90%] min-w-60 z-50 relative mt-18 overflow-hidden"
//       >
//         <button
//           className="absolute top-4 right-4 text-gred-500 hover:text-gray-600"
//           onClick={onClose}
//         >
//           <AiOutlineClose size={20} />
//         </button>
//         <h2 className="text-md font-bold">Choose a contact</h2>
//         <div className="w-full flex gap-1 flex-col justify-center overflow-scroll">
//           <div className="flex my-2 border-b borde-grey-500 py-2 align-middle items-center cursor-pointer">
//             <input type="checkbox" name="" id="" />
//             <div className="text-sm mx-2">
//               Select All (<span>{}</span>)
//             </div>
//           </div>
//           <div className="h-[300px] overflow-x-auto ">
//             {contacts.map((contact: any, index: number) => {
//               return (
//                 <div className="flex gap-1 w-full">
//                   <input type="checkbox" name="" id="" />
//                   <div
//                     key={index}
//                     className="cursor-pointer w-full m-1 hover:bg-gray-100 flex items-center justify-between  border p-1 rounded-lg border-gray-200"
//                     onClick={() => {
//                       handleContactSelect({
//                         fullName: contact.fullName,
//                         mobileNumber: contact.mobileNumber,
//                         url: contact.url,
//                       });
//                     }}
//                   >
//                     <div className="flex items-center">
//                       <div className="rounded-full mx-2 overflow-hidden h-[2.30rem] w-[2.30rem]">
//                         <img
//                           className="h-full w-full object-cover"
//                           src={contact.url}
//                           alt={contact.fullName}
//                         />
//                       </div>
//                       <div className="mx-2">
//                         <div className="flex-1">
//                           <div className="text-left text-sm">
//                             {contact.fullName}
//                           </div>
//                           <div className="text-sm text-gray-500 text-left">
//                             {contact.mobileNumber}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//           <Button
//             lable={"Select"}
//             gredient={true}
//             buttonClick={function (data: object): void {
//               throw new Error("Function not implemented.");
//             }}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SelectedContact;
