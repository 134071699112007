import {
  GET_PROFILE_INFO_REQUEST,
  GET_PROFILE_INFO_SUCCESS,
  GET_PROFILE_INFO_FAILURE,
} from "../../../Utils/Constant";

export const getProfileInfoRequest = () => {
  return {
    type: GET_PROFILE_INFO_REQUEST,
  };
};

export const getProfileInfoSuccess = (data: object) => {
  return {
    type: GET_PROFILE_INFO_SUCCESS,
    payload: data,
  };
};

export const getProfileInfofailure = (error: object) => {
  return {
    type: GET_PROFILE_INFO_FAILURE,
    payload: error,
  };
};
