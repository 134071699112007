import { combineReducers } from "redux";
import rootSaga from "../Sagas";
import configureStore from "../store";
import { logInReducer } from "./auth/logInReducer";
import { signUpReducer } from "./auth/signUpReducer";
import { registerRsendOtpReducer } from "./auth/RegisterResendOtpReducer";
import { verifyRegisterOtpReducer } from "./auth/VerifyRegisterOtpReducer";
import { verifyLoginOtpReducer } from "./auth/verifyLoginOtpReducer";
import { getProfileReducer } from "./profile/ProfileReducer";
import { mainCategoryReducer } from "./Form/MainCategoryReducer";
import { subCategoryReducer } from "./Form/subCategoryReducer";
import { createEventReducer } from "./Form/CreateEventReducer";
import { getEventReducer } from "./Form/GETEventReducer";
import { getEventIDReducer } from "./Form/GetEventIDReducer";
import { deleteEventReducer } from "./Event/DeleteEventReducer";
import { updateEventReducer } from "./Event/UpdateEventReducer";
import { deleteUserAccountReducer } from "./profile/DeleteUserAccountReducer";
import { getContactReducer } from "./Contact/GetContactReducer";
import { creditPlanReducer } from "./Credit/GetCreditPlanReducer";
import { googleContactReducer } from "./googleContact/googleContactReducer";
import { saveAndSendInvitationReducer } from "./invitation/saveAndSendInvitationReducer";
import { updateProfileReducer } from "./profile/UpdateProfileReducer";
import { updateCreditPlanReducer } from "./Credit/UpdateCreditPlanReducer";
import { getCreditHistoryReducer } from "./Credit/GetCreditHistoryReducer";
import { sendInvitationReducer } from "./invitation/sendInvitationReducer";
import { invitationReducer } from "./invitee/invitationReducer";
import { invitationResponseReducer } from "./invitee/invitationResponseReducer";
export default function setupStore() {
  const rootReducer = combineReducers({
    logInReducer,
    signUpReducer,
    registerRsendOtpReducer,
    verifyRegisterOtpReducer,
    verifyLoginOtpReducer,
    getProfileReducer,
    mainCategoryReducer,
    subCategoryReducer,
    createEventReducer,
    getEventReducer,
    getEventIDReducer,
    deleteEventReducer,
    updateEventReducer,
    deleteUserAccountReducer,
    getContactReducer,
    creditPlanReducer,
    googleContactReducer,
    saveAndSendInvitationReducer,
    updateProfileReducer,
    updateCreditPlanReducer,
    getCreditHistoryReducer,
    sendInvitationReducer,
    invitationReducer,
    invitationResponseReducer,
  });
  return configureStore(rootReducer, rootSaga);
}
