const GOOGLE_MAPS_API_KEY = "AIzaSyBTJBNve6Nwf0yjAxA2ZObrGUNZ7gaS6T0";

const getAddressFromCoordinates = async (
  latitude: any,
  longitude: any,
  setValue: any
) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`
    );
    const data = await response.json();
    if (data.status === "OK") {
      setValue("venueAddress", data.results[0].formatted_address);
    } else {
      console.error("Failed to fetch address:", data.status);
    }
  } catch (error) {
    console.error("Error fetching address:", error);
  }
};

export default getAddressFromCoordinates;

export const getLocation = (setValue: any) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // Do something with latitude and longitude
        setValue("latitude", latitude);
        setValue("longitude", longitude);
        getAddressFromCoordinates(latitude, longitude, setValue);
        // You can set these values in your form using setValue if needed
      },
      (error) => {
        console.error("Error getting user's location:", error);
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
};
