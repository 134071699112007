import {
  REGISTER_RESEND_OTP_REQUEST,
  REGISTER_RESEND_OTP_SUCCESS,
  REGISTER_RESEND_OTP_FAILURE,
} from "../../../Utils/Constant";

export const registerResendOtprequest = (payload: object) => {
  return {
    type: REGISTER_RESEND_OTP_REQUEST,
    payload: payload,
  };
};

export const registerResendOtpSuccess = (data: object) => {
  return {
    type: REGISTER_RESEND_OTP_SUCCESS,
    payload: data,
  };
};

export const registerResendOtpFailure = (error: object) => {
  return {
    type: REGISTER_RESEND_OTP_FAILURE,
    payload: error,
  };
};
