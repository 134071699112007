import { call, put } from "redux-saga/effects";
import { POST_API } from "../../../Services/API/ApiInstance";
import { authApi } from "../../../Utils/Constant";
import { signUpSuccess, signUpFailure } from "../../Actions";
import toast from "react-hot-toast";

function* signUpSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    toast.loading("Sending OTP ...");
    const res = yield call(POST_API, authApi.SIGN_UP, data);
    toast.dismiss();
    toast.success(res.message);
    yield put(signUpSuccess(res));
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.message);
    yield put(signUpFailure(error));
  }
}

export default signUpSaga;
