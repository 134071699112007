import React, { useEffect, useState } from "react";
import LandingPageHeader from "../../Component/LandingPageComponent/LandingPageHeader";
import AuthCard from "../../Component/AuthCard/AuthCard";
import LoginBg from "../../Asset/Svg/LoginBg.svg";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import {
  getProfileInfoRequest,
  postLogInRequest,
  verifyLoginOtpEmpty,
  verifyLoginOtpRequest,
} from "../../Redux/Actions";
import LoginContext from "../../Component/AuthCard/LoginContext";
import OTPContent from "../../Component/AuthCard/OTPContent";
import { postLogInEmpty } from "../../Redux/Actions/auth/loginActions";
interface LogInPageProps {
  login: any;
  verifyLogin: any;
}
const LogInPage: React.FC<LogInPageProps> = ({ login, verifyLogin }) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [value, setValue] = useState("");
  const [authOTP, setAuthOTP] = useState("");
  const [error, setError] = useState(false);
  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);
  const [otpErr, setOtpErr] = useState(false);
  const [backArrow, setBackArrow] = useState(false);
  const [header, setHeader] = useState("Log in");
  const dispatch = useDispatch();
  useEffect(() => {
    if (login?.data?.statusCode === 200 && !err) {
      setOtpSent(true);
      setBackArrow(true);
      setHeader("Enter code");
      setAuthOTP(login?.data?.data);
    }
  }, [err, login, login?.data?.data]);

  useEffect(() => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      navigate("/home");
    }
  }, []);

  const handleBack = () => {
    setOtpSent(false);
    setBackArrow(false);
    setHeader("Log in");
    // navigate("/home");
  };

  const onClose = () => {
    dispatch(postLogInEmpty());
    setOtpSent(false);
    setBackArrow(false);
    setHeader("Log in");
    navigate("/home");
  };

  useEffect(() => {
    return () => {
      dispatch(postLogInEmpty());
    };
  }, []);

  // Define your handleSendOtp function
  const handleSendOtp = () => {
    if (!value) {
      setError(true);
    } else {
      dispatch(postLogInRequest({ mobileNumber: value }));
      setError(false);
    }
  };
  const debouncedHandleSendOtp: () => void = debounce(handleSendOtp, 500);

  const handleClick = () => {
    debouncedHandleSendOtp();
  };

  const handleMobileChanges = (data: any) => {
    setValue(data);
  };

  const handleOTPValidation = (data: any) => {
    if (!data || data.length !== 4) {
      setOtpErr(true);
      setBackArrow(true);
      return;
    } else {
      dispatch(
        verifyLoginOtpRequest({
          mobileNumber: value,
          otp: data,
        })
      );
    }
  };

  useEffect(() => {
    setOtpErr(false);
    if (verifyLogin?.data?.statusCode === 200) {
      if (toggle) {
        localStorage.setItem("token", verifyLogin?.info?.data?.accessToken);
      }
      sessionStorage.setItem("token", verifyLogin?.info?.data?.accessToken);
      setOtpErr(false);
      navigate("/");
    } else {
      if (verifyLogin?.data) {
        setTimeout(() => {
          setOtpErr(true);
        }, 1000);
      }
    }

    if (verifyLogin?.error) {
      
    }
  }, [verifyLogin]);

  useEffect(() => {
    return () => {
      dispatch(verifyLoginOtpEmpty());
    };
  }, []);

  const handleResendOtp = () => {
    setOtpErr(false);
    handleSendOtp();
  };
  // bg - [#F4E4E4];
  return (
    <div
      className="w-full bg-cover bg-center h-screen overflow-clip"
      style={{ backgroundImage: `url(${LoginBg})` }}
    >
      <LandingPageHeader bgColor={"bg-[#F4E4E4]"} />
      <AuthCard
        handleBack={handleBack}
        headerLable={header}
        backArrow={backArrow}
        closeIcon={!backArrow}
        onClose={onClose}
      >
        <>
          {!otpSent ? (
            <LoginContext
              error={error}
              value={value}
              handleMobileChanges={handleMobileChanges}
              toggle={toggle}
              setToggle={setToggle}
              handleSendOtp={handleClick}
            />
          ) : (
            <OTPContent
              title={`We’ve sent an SMS with an activation code to your phone ${authOTP} ${value}`}
              mobile={""}
              message={"Send code again"}
              count={30}
              errMSg={
                "Code is incorrect. Check the code again and enter it correctly."
              }
              err={otpErr}
              handleOTPValidation={(data) => {
                handleOTPValidation(data);
              }}
              handleResendOtp={handleResendOtp}
              handleOtpError={() => setOtpErr(false)}
            />
          )}
        </>
      </AuthCard>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    login: state.logInReducer,
    verifyLogin: state.verifyLoginOtpReducer,
  };
};

export default connect(mapStateToProps)(LogInPage);
