import {
  GET_CREDIT_PLAN_REQUEST,
  GET_CREDIT_PLAN_SUCCESS,
  GET_CREDIT_PLAN_FAILURE,
} from "../../../Utils/Constant";

export const getCreditPlanRequest = () => {
  return {
    type: GET_CREDIT_PLAN_REQUEST,
  };
};

export const getCreditPlanSuccess = (data: object) => {
  return {
    type: GET_CREDIT_PLAN_SUCCESS,
    payload: data,
  };
};

export const getCreditPlanFailure = (error: object) => {
  return {
    type: GET_CREDIT_PLAN_FAILURE,
    payload: error,
  };
};
