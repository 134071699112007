import {
  DELETE_USER_ACCOUNT_REQUEST,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT_FAILURE,
  DELETE_USER_ACCOUNT_EMPTY,
} from "../../../Utils/Constant";

export const deleteUserAccountRequest = () => {
  return {
    type: DELETE_USER_ACCOUNT_REQUEST,
  };
};

export const deleteUserAccountSuccess = (data: object) => {
  return {
    type: DELETE_USER_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const deleteUserAccountFailure = (error: object) => {
  return {
    type: DELETE_USER_ACCOUNT_FAILURE,
    payload: error,
  };
};

export const deleteUserAccountReset = () => {
  return {
    type: DELETE_USER_ACCOUNT_EMPTY,
  };
};
