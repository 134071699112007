import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API } from "../../../Services/API/ApiInstance";
import { getEventFailure, getEventSuccess } from "../../Actions";

function* getEventSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  const pageNo = data.pageNo;
  const pageSize = data.pageSize;
  const filterBy = data.filterBy;

  function buildURL(
    pageNo: number | undefined,
    pageSize: number | undefined,
    filterBy: string | undefined
  ) {
    let url = `${authApi.GET_EVENTS}`;

    if (pageNo || pageSize || filterBy) {
      url += "?";

      if (pageNo) {
        url += "pageNo=" + encodeURIComponent(pageNo) + "&";
      }
      if (pageSize) {
        url += "pageSize=" + encodeURIComponent(pageSize) + "&";
      }
      if (filterBy) {
        url += "filterBy=" + encodeURIComponent(filterBy);
      }
    }

    return url;
  }

  const newurl = buildURL(pageNo, pageSize, filterBy);

  const path = newurl;

  try {
    const res = yield call(GET_API, path);
    yield put(getEventSuccess(res.data));
  } catch (error: any) {
    if (error?.response?.data?.statusCode === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    }
    toast.error(error?.response?.data?.message);
    yield put(getEventFailure(error));
  }
}

export default getEventSaga;
