import {
  POST_LOG_IN_FAILURE,
  POST_LOG_IN_REQUEST,
  POST_LOG_IN_SUCCESS,
  POST_LOG_IN_EMPTY,
} from "../../../Utils/Constant";

const initialState = {
  data: null,
  loading: false,
  error: null,
  info: {},
};
export const logInReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case POST_LOG_IN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        info: action.payload,
      };
    case POST_LOG_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        info: action.payload,
        error: null,
      };
    case POST_LOG_IN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case POST_LOG_IN_EMPTY:
      return {
        ...state,
        data: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
