import { put, call } from "redux-saga/effects";
import { POST_API } from "../../../Services/API/ApiInstance";
import { authApi } from "../../../Utils/Constant";
import { postLogInfailure, postLogInSuccess } from "../../Actions";
import toast from "react-hot-toast";

function* logInSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    toast.loading("Sending OTP...");
    const res = yield call(POST_API, authApi.LOG_IN, data);
    toast.dismiss();
    toast.success(res.message);
    yield put(postLogInSuccess(res));
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.message);
    yield put(postLogInfailure(error));
  }
}

export default logInSaga;
