import React, { useState } from "react";
import HomeCard from "../../Component/Home/HomeCard";
import { emptyGallery } from "../../Asset";
import Button from "../../Component/AuthButton/Button";

const Gallery = () => {
  const [hide, setHide] = useState(false);
  const arrayImage = [
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
    "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg",
  ];
  const header = "Gallery";
  return (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute  top-12 text-center overflow-clip">
      <div className="h-full w-full flex flex-col items-center">
        <div className="text-[#024773] text-[1.6rem] font-bold w-full mb-4">
          {header}
        </div>
        <HomeCard>
          {hide ? (
            <div className="w-full h-full flex flex-col justify-center items-center mb-14 ">
              <div className="">
                <p className="text-[0.9rem] my-4 text-[#024773]">
                  Profile Picture
                </p>
                <img src={emptyGallery} alt="" />
              </div>
              <div className="font-bold text-[#024773] text-[1.5rem] mt-6">
                Event Photos
              </div>
              <Button
                mainStyle="w-1/3 min-w-60"
                lable={"Add Photos"}
                gredient={true}
                buttonClick={function (data: object): void {
                  throw new Error("Function not implemented.");
                }}
              />
              <div className="w-3/4 h-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-1 overflow-scroll mb-6">
                {arrayImage.map((item, index) => (
                  <img
                    key={index}
                    src={item}
                    alt=""
                    className="h-36 w-auto object-cover"
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center my-14 ">
              <div>
                <img src={emptyGallery} alt="" />
              </div>
              <div className="font-bold text-[1.5rem] mt-8 mb-2">
                No added photos
              </div>
              <div className=" text-center font-[200] mb-9">
                Make sure you have added photos of events{" "}
              </div>
              <Button
                mainStyle="w-1/3 min-w-60"
                lable={"Add Photos"}
                gredient={true}
                buttonClick={function (data: object): void {
                  throw new Error("Function not implemented.");
                }}
              />
            </div>
          )}
        </HomeCard>
      </div>
    </div>
  );
};

export default Gallery;
