import { call, put } from "redux-saga/effects";
import { POST_API } from "../../../Services/API/ApiInstance";
import { authApi } from "../../../Utils/Constant";
import {
  registerResendOtpSuccess,
  registerResendOtpFailure,
} from "../../Actions";
import toast from "react-hot-toast";

function* registerResendOtpSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    toast.loading("Sending OTP ...");
    const res = yield call(POST_API, authApi.REGISTER_RESEND_OTP, data);
    toast.dismiss();
    toast.success("OTP Sent");
    yield put(registerResendOtpSuccess(res));
  } catch (error: any) {
    toast.dismiss();
    toast.error("Sommeting Went Wrong");
    yield put(registerResendOtpFailure(error));
  }
}

export default registerResendOtpSaga;
