import {
  VERFY_LOGIN_OTP_REQUEST,
  VERFY_LOGIN_OTP_SUCCESS,
  VERFY_LOGIN_OTP_FAILURE,
  VERFY_LOGIN_OTP_EMPTY,
} from "../../../Utils/Constant";

export const verifyLoginOtpRequest = (payload: object) => {
  return {
    type: VERFY_LOGIN_OTP_REQUEST,
    payload: payload,
  };
};

export const verifyLoginOtpSuccess = (data: object) => {
  return {
    type: VERFY_LOGIN_OTP_SUCCESS,
    payload: data,
  };
};

export const verifyLoginOtpFailure = (error: object) => {
  return {
    type: VERFY_LOGIN_OTP_FAILURE,
    payload: error,
  };
};
export const verifyLoginOtpEmpty = () => {
  return {
    type: VERFY_LOGIN_OTP_EMPTY,
  };
};
