import { all, takeLatest } from "redux-saga/effects";
import {
  POST_LOG_IN_REQUEST,
  REGISTER_RESEND_OTP_REQUEST,
  SIGN_UP_REQUEST,
  VERFY_SIGUP_OTP_REQUEST,
  VERFY_LOGIN_OTP_REQUEST,
  GET_PROFILE_INFO_REQUEST,
  GET_MAIN_CATEGORY_REQUEST,
  GET_SUB_CATEGORY_REQUEST,
  CREATE_EVENT_REQUEST,
  GET_EVENT_REQUEST,
  GET_EVENT_ID_REQUEST,
  DELETE_EVENT_REQUEST,
  UPDATE_EVENT_REQUEST,
  DELETE_USER_ACCOUNT_REQUEST,
  GET_CONTACT_REQUEST,
  GET_CREDIT_PLAN_REQUEST,
  SAVE_GOOGLE_CONTACT_REQUEST,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PAYMENT_REQUEST,
  GET_TRANSITION_REQUEST,
  SEND_INVITATION_REQUEST,
  INVITEE_PAGE_REQUEST,
  INVITEE_RESPONSE_PAGE_REQUEST,
} from "../../Utils/Constant";
import logInSaga from "./auth/logInSaga";
import signUpSaga from "./auth/signUpSaga";
import registerResendOtpSaga from "./auth/registerResendOtpSaga";
import verifyRegisterOtpSaga from "./auth/verifyRegisterOtpSaga";
import verifyLoginOtpSaga from "./auth/verifyLoginOtpSaga";
import getProfileSaga from "./profile/ProfileSaga";
import mainCategorySaga from "./Form/MainCategorySaga";
import subCategorySaga from "./Form/subCategorySaga";
import createEventSaga from "./Form/CreateEventSaga";
import getEventsSaga from "./Form/getEventSaga";
import getEventIDSaga from "./Form/getEventIDSaga";
import deleteEventSaga from "./Event/DeleteEventSaga";
import updateEventSaga from "./Event/UpdateEventSaga";
import deleteUserAccountSaga from "./profile/DeleteUserAccountSaga";
import getContactSaga from "./Contact/getContactSaga";
import creditPlanSaga from "./Credit/GetCreditPlanSaga";
import saveAndSendInvitationSaga from "./invitation/saveAndSendInvitationSaga";
import updateProfileSaga from "./profile/updateProfileSaga";
import updateCreditPlanSaga from "./Credit/updateCreditPlanSaga";
import GetCreditHistorySaga from "./Credit/GetCreditHistorySaga";
import sendInvitationSaga from "./invitation/sendInvitationSaga";
import invitationSaga from "./invitee/invitationSaga";
import invitationResponseSaga from "./invitee/invitationResponseSaga";

export default function* rootSaga() {
  yield all([
    takeLatest(POST_LOG_IN_REQUEST, logInSaga),
    takeLatest(SIGN_UP_REQUEST, signUpSaga),
    takeLatest(REGISTER_RESEND_OTP_REQUEST, registerResendOtpSaga),
    takeLatest(VERFY_SIGUP_OTP_REQUEST, verifyRegisterOtpSaga),
    takeLatest(VERFY_LOGIN_OTP_REQUEST, verifyLoginOtpSaga),
    takeLatest(GET_PROFILE_INFO_REQUEST, getProfileSaga),
    takeLatest(GET_MAIN_CATEGORY_REQUEST, mainCategorySaga),
    takeLatest(GET_SUB_CATEGORY_REQUEST, subCategorySaga),
    takeLatest(CREATE_EVENT_REQUEST, createEventSaga),
    takeLatest(GET_EVENT_REQUEST, getEventsSaga),
    takeLatest(GET_EVENT_ID_REQUEST, getEventIDSaga),
    takeLatest(DELETE_EVENT_REQUEST, deleteEventSaga),
    takeLatest(UPDATE_EVENT_REQUEST, updateEventSaga),
    takeLatest(DELETE_USER_ACCOUNT_REQUEST, deleteUserAccountSaga),
    takeLatest(GET_CONTACT_REQUEST, getContactSaga),
    takeLatest(GET_CREDIT_PLAN_REQUEST, creditPlanSaga),
    takeLatest(SAVE_GOOGLE_CONTACT_REQUEST, saveAndSendInvitationSaga),
    takeLatest(UPDATE_PROFILE_REQUEST, updateProfileSaga),
    takeLatest(UPDATE_PAYMENT_REQUEST, updateCreditPlanSaga),
    takeLatest(GET_TRANSITION_REQUEST, GetCreditHistorySaga),
    takeLatest(SEND_INVITATION_REQUEST, sendInvitationSaga),
    takeLatest(INVITEE_PAGE_REQUEST, invitationSaga),
    takeLatest(INVITEE_RESPONSE_PAGE_REQUEST, invitationResponseSaga),
  ]);
}
