import React from "react";
import "./button.css";
interface ButtonProps {
  mainStyle?: string;
  lable: string;
  gredient: boolean;
  disable?: boolean;
  type?: string | "";
  buttonClick: (data: object) => void;
}
const Button: React.FC<ButtonProps> = ({
  mainStyle,
  lable,
  gredient,
  disable,
  buttonClick,
}) => {
  return (
    <button
      onClick={() => buttonClick({})}
      disabled={disable}
      className={`${
        gredient ? "gridient-button" : "normal-button"
      } ${mainStyle} text-center h-9 text-[0.9rem]text-white rounded-lg px-10`}
    >
      {lable}
    </button>
  );
};

export default Button;
