import React, { useState, useEffect } from "react";
import OTPInputComponent from "../OTPInput";
import Button from "../AuthButton/Button";
import { useNavigate } from "react-router-dom";

interface OTPPage {
  title: string;
  mobile?: string;
  message: string;
  count: number;
  errMSg: string;
  err: boolean;
  handleOTPValidation: (otp: string) => void;
  handleResendOtp: () => void;
  handleOtpError: () => void;
}

const OTPContent: React.FC<OTPPage> = ({
  title,
  mobile,
  message,
  count,
  errMSg,
  err,
  handleOTPValidation,
  handleResendOtp,
  handleOtpError,
}) => {
  const [otp, setOtp] = useState<string>("");
  const [timer, setTimer] = useState<number>(count); // Countdown timer
  const navigate = useNavigate();
  const [buttonTitle, setButtonTitile] = useState("Next");

  // TIMER COUNTDOWN FUNCTIONALITY
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(countdown);
          return prevTimer;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [timer]);

  const handleResend = () => {
    if (timer > 0) {
      return;
    } else {
      setTimer(count);
      handleResendOtp();
      setOtp("");
    }
  };

  useEffect(() => {
    if (otp.length === 4 && !err) {
      setButtonTitile("Verify");
    } else {
      setButtonTitile("Next");
    }
  }, [otp, err]);

  const handleChnage = (e: any) => {
    if (e) {
      setOtp(e);
      handleOtpError();
    } else {
      setOtp("");
    }
  };
  return (
    <>
      <div className="w-full flex justify-center mt-2">
        <div className=" text-[0.9rem] text-center">{title} &nbsp;&nbsp;</div>
      </div>
      <div className="w-full flex justify-center mt-12 mb-4">
        <OTPInputComponent
          numInputs={4}
          inputStyle={`text-center rounded-lg p-2 select-none ${
            err
              ? "border border-red-700 text-red-700"
              : "border border-[#cccccc]"
          }`}
          containerStyle={""}
          value={otp ? otp : ""}
          onChange={(e) => handleChnage(e)}
        />
      </div>
      <div className="w-full flex justify-center text-center text-red-700 text-[0.8rem] my-5">
        {err ? errMSg : ""}
      </div>
      <div className="w-full flex justify-center">
        <div
          className={`text-[0.9rem] select-none ${
            timer < 1 ? "cursor-pointer" : "cursor-wait"
          }`}
          onClick={handleResend}
        >
          <span className="font-medium">
            {" "}
            {timer < 1 ? "I didn’t receive a code" : message}
          </span>{" "}
          &nbsp;
          <span className={timer < 10 ? "blink" : ""}>
            {timer < 1 ? "Resend" : `00:${timer < 10 ? `0${timer}` : timer}`}
          </span>
        </div>
      </div>
      <div className="w-full flex justify-center mt-5">
        <Button
          mainStyle={"w-[15rem]"}
          lable={buttonTitle}
          gredient={true}
          buttonClick={() => handleOTPValidation(otp)}
        />
      </div>
    </>
  );
};

export default OTPContent;
