import React from "react";
import { AiOutlineClose } from "react-icons/ai"; // Importing the close icon
import Button from "../Component/AuthButton/Button";

interface BuyCreditModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  message: string;
}

const BuyCreditModal: React.FC<BuyCreditModalProps> = ({
  onCancel,
  onConfirm,
  message,
}) => {
  return (
    <div className="fixed md:pl-[20vw] w-screen inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-30"></div>
      <div
        id="contact-modal"
        className="bg-white rounded-lg p-6 w-96 z-50 relative h-fit mt-18 overflow-hidden"
      >
        <button
          className="absolute top-4 right-4 text-gred-500 hover:text-gray-600"
          onClick={onCancel}
        >
          <AiOutlineClose size={20} />
        </button>
        <h2 className="text-md font-bold">Buy Credits</h2>
        <div className="w-full flex gap-1 flex-col justify-center overflow-scroll">
          <div className="h-fit overflow-x-auto ">
            <p className="m-2  mt-4 text-sm text-center">{message}</p>
            <div className="flex justify-around m-2">
              <Button
                lable={"Dismiss"}
                gredient={false}
                buttonClick={onCancel}
              />
              <Button
                lable={"Buy Credit"}
                gredient={true}
                buttonClick={onConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyCreditModal;
