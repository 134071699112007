import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
interface FormHeaderProps {
  header: string;
  message: string;
  onBack: () => void;
}
const FormHeader: React.FC<FormHeaderProps> = ({ header, message, onBack }) => {
  return (
    <div className="md:w-3/4 lg:w-3/4 xl:w-1/2 w-[90%] max-w-[40rem] flex">
      <div
        className="w-max h-max  p-1.5 rounded-lg border border-grey cursor-pointer flex justify-center items-center"
        onClick={() => onBack()}
      >
        <MdArrowBackIosNew style={{ width: "15px", height: "15px" }} />
      </div>
      <div className="w-full">
        <p className=" text-[#024773]  text-[1.6rem] font-bold w-full">
          {header}
        </p>
        <p className="text-[0.8rem] text-[#494444] font-[200]">{message}</p>
      </div>
    </div>
  );
};

export default FormHeader;
