import React from "react";
interface InputComponent {
  lable: string;
  mainStyle?: string;
  disable?: boolean;
  type: string;
  onChange?: (data: any) => void;
  lableStyle?: string;
  placeHolder: string;
  inputStyle: string;
  err?: boolean;
  errMsg?: string;
  value: any;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
const InputComponent: React.FC<InputComponent> = ({
  lable,
  mainStyle,
  disable,
  type,
  errMsg,
  lableStyle,
  inputStyle,
  onChange,
  err,
  placeHolder,
  value,
}) => {
  return (
    <div className={`${mainStyle} flex flex-col`}>
      {lable && <p className={lableStyle}>{lable}</p>}
      <input
        type={type}
        placeholder={placeHolder}
        disabled={disable}
        value={value}
        className={`${
          inputStyle || ""
        }  px-2 py-2 rounded-lg shadow placeholder:text-[0.8rem] ${
          err ? "border border-red-400 " : "border border-[#cccccc]"
        } text-[0.8rem] h-[2.7rem]`}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
    </div>
  );
};

export default InputComponent;
