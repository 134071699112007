import React from "react";

interface TransactionTableProps {
  transactionData: any[];
}

const TransactionTable: React.FC<TransactionTableProps> = ({
  transactionData = [],
}) => {
  const tableHeader = ["Transaction id", "Date", "Plan type", "Amount"];

  return (
    <div className="rounded border border-gray-200 mx-4 overflow-scroll">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {tableHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-1 py-3 text-xs text-center font-medium text-gray-500 tracking-wider"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {transactionData.map((transaction, index) => (
            <tr key={index}>
              <td className="px-4 py-2 whitespace-nowrap text-xs text-[#4d4d4d]">
                {transaction?.transactionId}
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-xs text-[#4d4d4d]">
                {transaction?.createDate}
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-xs text-[#4d4d4d]">
                {transaction?.planName}
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-xs text-[#4d4d4d]">
                Rs {transaction?.amount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
