import React, { useEffect, useState } from "react";
import OAuthLogin from "./OAuthLogin";
import OAuthLogOut from "./OAuthLogOut";
import { gapi } from "gapi-script";
import HomeCard from "../Home/HomeCard";
import { useDispatch } from "react-redux";
import { getContactRequest } from "../../Redux/Actions";
interface OAuthMainProps {
  isLogin: boolean;
}
const clientId =
  "956209229820-kql53hoebvr8islknh8hkluqtsimo6tj.apps.googleusercontent.com";

const OAuthMain: React.FC<OAuthMainProps> = ({ isLogin }) => {
  const [contactGroups, setContactGroups] = useState<any[]>([]);
  const [accessToken, setAccessToken] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    let authInstance;
    function start() {
      gapi.load("client:auth2", () => {
        gapi.client
          .init({
            clientId: clientId,
            scope:
              "https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/userinfo.profile",
          })
          .then(() => {
            authInstance = gapi.auth2.getAuthInstance();
            const isSignedIn = authInstance.isSignedIn.get();
            if (isSignedIn) {
              fetchContactGroups();
            } else {
              authInstance.isSignedIn.listen((signedIn: any) => {
                if (signedIn) {
                  fetchContactGroups();
                } else {
                  setContactGroups([]);
                }
              });
            }
          })
          .catch((error: any) => {
            console.error("Error initializing gapi.auth2:", error);
          });
      });
    }
    gapi.load("client:auth2", start);
  }, [accessToken]);

  const fetchContactGroups = async () => {
    if (!accessToken) return;
    const maxResults = 1000; // Set a maximum number of results to retrieve, adjust as needed
    let nextPageToken = null;
    let allContacts: React.SetStateAction<any[]> = [];

    try {
      do {
        const res: any = await fetch(
          `https://people.googleapis.com/v1/people/me/connections?personFields=names,phoneNumbers,photos&pageSize=${maxResults}&pageToken=${
            nextPageToken || ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (!res.ok) {
          throw new Error("Failed to fetch contacts");
        }
        const data = await res.json();
        const contactsArray = data.connections.map((connection: any) => ({
          fullName: connection.names && connection.names[0].displayName,
          mobileNumber:
            connection.phoneNumbers &&
            connection?.phoneNumbers[0]?.canonicalForm,
          url: connection?.photos[0].url,
        }));
        allContacts = [...allContacts, ...contactsArray];

        nextPageToken = data.nextPageToken;
      } while (nextPageToken);

      setContactGroups(allContacts);
      dispatch(getContactRequest(allContacts));
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const handleSignOut = () => {
    gapi.auth2
      .getAuthInstance()
      .signOut()
      .then(() => {
        setContactGroups([]);
        dispatch(getContactRequest([]));
      })
      .catch((error: any) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <div className="w-full h-full">
      <div className="flex justify-center w-full h-max">
        {isLogin ? (
          <OAuthLogin setAccessToken={setAccessToken} />
        ) : (
          <OAuthLogOut onSignOut={handleSignOut} />
        )}
      </div>
    </div>
  );
};

export default OAuthMain;
