import {
  POST_LOG_IN_REQUEST,
  POST_LOG_IN_SUCCESS,
  POST_LOG_IN_FAILURE,
  POST_LOG_IN_EMPTY,
} from "../../../Utils/Constant";

export const postLogInRequest = (payload: object) => {
  return {
    type: POST_LOG_IN_REQUEST,
    payload: payload,
  };
};

export const postLogInSuccess = (data: object) => {
  return {
    type: POST_LOG_IN_SUCCESS,
    payload: data,
  };
};

export const postLogInfailure = (error: object) => {
  return {
    type: POST_LOG_IN_FAILURE,
    payload: error,
  };
};
export const postLogInEmpty = () => {
  return {
    type: POST_LOG_IN_EMPTY,
  };
};
