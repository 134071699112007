import { call, put } from "redux-saga/effects";
import { PATCH_API } from "../../../Services/API/ApiInstance";
import { authApi } from "../../../Utils/Constant";
import { verifyLoginOtpSuccess, verifyLoginOtpFailure } from "../../Actions";
import toast from "react-hot-toast";

function* verifySignupOtpSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(PATCH_API, authApi.LOGIN_OTP_VERFICATION, data);
    toast.dismiss();
    toast.success(res.message);
    yield put(verifyLoginOtpSuccess(res));
  } catch (error: any) {
    console.error(error);
    toast.dismiss();
    yield put(verifyLoginOtpFailure(error));
  }
}

export default verifySignupOtpSaga;
