import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./NavBar";
interface LayoutProps {
  user: string;
}

const Layout: React.FC<LayoutProps> = ({ user }) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(true);
  const [userData, setUserData] = useState<string>("");

  function handleSize() {
    if (window.innerWidth > 768) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
      setUserData("");
    }
  }

  useEffect(() => {
    handleSize();
    window.addEventListener("resize", handleSize);

    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  return (
    <div>
      <Navbar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        user_name={user}
      />
      <Sidebar showSidebar={showSidebar} />
    </div>
  );
};

export default Layout;
