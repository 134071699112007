import React from "react";
import InputComponent from "../InputComponent/InputComponent";
import InputSuggestion from "../InputComponent/InputSuggestion";
import { useNavigate } from "react-router-dom";
import Button from "../AuthButton/Button";
interface SignUpContent {
  setCountry: (data: any) => void;
  country: {
    code: string;
    name: string;
  };
  setUserName: (data: any) => void;
  setMobileNumber: (data: any) => void;
  mobileNumber: string;
  handleSignUp: () => void;
  err: boolean;
}
const SignUpContent: React.FC<SignUpContent> = ({
  setCountry,
  country,
  setUserName,
  setMobileNumber,
  mobileNumber,
  handleSignUp,
  err,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full flex justify-center">
        <div className=" text-[0.9rem] text-center">
          Create an account in just 8 seconds
        </div>
      </div>
      <div className="w-full flex justify-center mt-9">
        <InputComponent
          lableStyle="text-[0.8rem]"
          lable={"Your Name"}
          type={"text"}
          onChange={(e) => setUserName(e)}
          placeHolder={"Your Name"}
          inputStyle={"w-full appearance-none leading-tight"}
          mainStyle={"w-72 md:w-1/2"}
          value={undefined}
          err={err}
        />
      </div>
      <div className="w-full flex justify-center mt-3">
        <InputSuggestion
          lableStyle="text-[0.8rem]"
          lable={"Country"}
          type={"text"}
          onChange={(data: any) => setCountry(data)}
          placeHolder={"Country Name"}
          inputStyle={"w-full appearance-none leading-tight"}
          mainStyle={"w-72 md:w-1/2"}
          value={country}
          err={err}
        />
      </div>
      <div className="w-full flex justify-center mt-3">
        <div className="flex flex-row w-72 md:w-1/2">
          <InputComponent
            lableStyle="text-[0.8rem] absolute top-[18.3rem]"
            lable={"Mobile Number"}
            type={"text"}
            placeHolder={"+91"}
            inputStyle={"w-full appearance-none leading-tight text-[0.8rem]"}
            mainStyle={"w-[20%] mt-5"}
            value={country?.code?.toString()}
            disable={true}
            err={err}
          />
          <InputComponent
            lableStyle="text-[0.8rem]"
            lable={""}
            type={"number"}
            onChange={(e) => setMobileNumber(e)}
            placeHolder={"Mobile Number"}
            inputStyle={"w-full appearance-none leading-tight text-[0.8rem]"}
            mainStyle={"w-[80%] ml-1 mt-5"}
            value={mobileNumber}
            err={err}
          />
        </div>
      </div>
      <div className="w-full flex justify-center mt-6">
        <Button
          mainStyle="w-[16rem]"
          lable={"Signup and Proceed"}
          gredient={true}
          buttonClick={() => handleSignUp()}
        />
      </div>
      <div className="w-full flex justify-center font-[300] text-[0.8rem] mt-4">
        {" "}
        Already have an account?{" "}
        <span
          className="font-bold cursor-pointer underline"
          onClick={() => navigate("/login")}
        >
          {" "}
          &nbsp; Log in
        </span>
      </div>
    </>
  );
};

export default SignUpContent;
