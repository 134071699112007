import React from "react";
import Button from "../AuthButton/Button";
import { useNavigate } from "react-router-dom";
import { landingPageButton } from "../data";
import { HomeLogo } from "../../Asset";
interface LandingPageHeader {
  bgColor: string;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
const LandingPageHeader: React.FC<LandingPageHeader> = ({ bgColor }) => {
  const navigate = useNavigate();
  const handleClick = (data: any) => {
    navigate(`${data.route}`);
  };
  return (
    <div className={`${bgColor} sticky top-0 flex justify-between items-cente`}>
      <div className="hidden md:block">
        <img src={HomeLogo} alt="logo" style={{ height: 80 }} />
      </div>
      <div className="flex w-full items-center justify-between md:justify-end truncate">
        {landingPageButton.map((item, index) => (
          <Button
            key={item + "" + index}
            lable={item.lable}
            gredient={item.gredient}
            mainStyle={item.mainStyle}
            buttonClick={() => handleClick(item)}
          />
        ))}
      </div>
    </div>
  );
};

export default LandingPageHeader;
