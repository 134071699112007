import {
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
} from "../../../Utils/Constant";

export const deleteEventRequest = (id: number) => {
  return {
    type: DELETE_EVENT_REQUEST,
    payload: id,
  };
};

export const deleteEventSuccess = (data: object) => {
  return {
    type: DELETE_EVENT_SUCCESS,
    payload: data,
  };
};

export const deleteEventFailure = (error: object) => {
  return {
    type: DELETE_EVENT_FAILURE,
    payload: error,
  };
};
