import React from "react";
import { AiOutlineClose } from "react-icons/ai"; // Importing the close icon
import Button from "../Component/AuthButton/Button";

interface DeleteConfirmationModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmationModal: React.FC<DeleteConfirmationModalProps> = (props) => {
  const { onCancel, onConfirm, message } = props;
  return (
    <div className="fixed md:pl-[20vw] w-screen inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div
        id="contact-modal"
        className="bg-white rounded-lg p-6 w-max z-50 relative h-max mt-18 overflow-hidden"
      >
        <button
          className="absolute top-4 right-4 text-gred-500 hover:text-gray-600"
          onClick={onCancel}
        >
          <AiOutlineClose size={20} />
        </button>
        <div className="w-full mt-4 flex gap-1 flex-col justify-center overflow-scroll">
          <div className="h-max overflow-x-auto">
            <p className="m-4 text-sm text-center">{message}</p>

            <div className="flex justify-around">
              <Button
                lable={"Cancel"}
                gredient={false}
                buttonClick={onCancel}
              />
              <Button
                lable={"Confirm"}
                gredient={true}
                buttonClick={onConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
