import React from "react";
interface ToggleButtonEvent {
  options: string[];

  selectedTab: number;
  setSelectedTab: (data: number) => void;
}
const ToggleButtonEvent: React.FC<ToggleButtonEvent> = ({
  options,
  selectedTab,
  setSelectedTab,
}) => {
  return (
    <div className="w-full flex justify-center">
      <div className="w-[60%] max-w-[24rem] min-w-[18rem] flex justify-center bg-[#D8D8D8] mt-8 mb-4 rounded-full">
        {options.map((item, index) => {
          return (
            <div
              key={index}
              className={`${
                selectedTab === index
                  ? "bg-[#024773] text-white"
                  : "text-[#666666]"
              } w-3/4 max-w-48 min-w-36 rounded-full text-center py-2 font-[300] text-[0.8rem] cursor-pointer truncate`}
              onClick={() => setSelectedTab(index)}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ToggleButtonEvent;
