import { Navigate, Outlet } from "react-router-dom";
import Layout from "../Component/UI/Layout";
export const ProtectedRoutes = () => {
  const localToken = localStorage.getItem("token");
  const sessionToken = sessionStorage.getItem("token");
  const token = localToken || sessionToken;

  return token ? (
    <div className="h-screen bg-[#F4E4E4]">
      <Layout user="Invite Pro" />
      <Outlet />
    </div>
  ) : (
    <Navigate to="/home" />
  );
};
