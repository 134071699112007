import {
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
} from "../../../Utils/Constant";

export const getEventRequest = (pageNo?: number, pageSize?: number, filterBy?: string) => {
  return {
    type: GET_EVENT_REQUEST,
    payload: { pageNo, pageSize, filterBy },
  };
};

export const getEventSuccess = (data: object) => {
  return {
    type: GET_EVENT_SUCCESS,
    payload: data,
  };
};

export const getEventFailure = (error: object) => {
  return {
    type: GET_EVENT_FAILURE,
    payload: error,
  };
};

export const getEventReset = () => ({
    type: "GET_EVENT_RESET",
});
