import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { POST_API } from "../../../Services/API/ApiInstance";
import {
  saveAndSendInvitationSuccess,
  saveAndSendInvitationFailure,
} from "../../Actions";

function* saveAndSendInvitationSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(POST_API, authApi.SAVE_CONTACT, data);
    toast.success(res.message);
    yield put(saveAndSendInvitationSuccess(res.data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    yield put(saveAndSendInvitationFailure(error));
  }
}

export default saveAndSendInvitationSaga;
