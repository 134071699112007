import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
interface AuthCardHeader {
  headerLable: string;
  onBack: () => void;
  onClose: () => void;
  backArrow: boolean;
  closeIcon: boolean;
}
const AuthCardHeader: React.FC<AuthCardHeader> = ({
  headerLable,
  onBack,
  onClose,
  backArrow,
  closeIcon,
}) => {
  return (
    <div className="flex -mb-4 mt-8">
      {backArrow && (
        <div
          className="w-max p-1.5 rounded-lg border border-grey cursor-pointer flex justify-center items-center"
          onClick={() => onBack()}
        >
          <MdArrowBackIosNew style={{ width: "15px", height: "15px" }} />
        </div>
      )}
      <div className=" flex w-full justify-center">
        <div
          className={`${
            closeIcon ? "" : "-ml-9"
          } text-center items-center text-popins text-[1.3rem] text-[#024773] font-bold`}
        >
          {headerLable}
        </div>
      </div>
      {closeIcon && (
        <div
          className="w-max cursor-pointer flex justify-center items-center -mt-14 -mr-3 text-[1.9rem]  text-[#0074A1]"
          onClick={() => onClose()}
        >
          <IoIosCloseCircleOutline />
        </div>
      )}
    </div>
  );
};

export default AuthCardHeader;
