import React from "react";
import { TimePicker } from "react-time-picker-typescript";
import "react-time-picker-typescript/dist/style.css";
import "../../../src/index.css";
import { FaRegClock } from "react-icons/fa6";
interface FormTimeProps {
  register: any;
  startDate: string | undefined;
  endDate: string | undefined;
  isReviewed: boolean;
  setValue?: any;
  getValues?: any;
}
const FormTime: React.FC<FormTimeProps> = ({
  register,
  startDate,
  endDate,
  isReviewed,
  setValue,
  getValues,
}) => {
  const handleStartDate = (value: any) => {
    const currentTime: Date = new Date();
    const selectedTimeParts: string[] = value.split(":");
    const selectedTime: Date = new Date();
    selectedTime.setHours(parseInt(selectedTimeParts[0], 10));
    selectedTime.setMinutes(parseInt(selectedTimeParts[1], 10));

    let timeDifference: number = selectedTime.getTime() - currentTime.getTime();

    if (timeDifference < 0) {
      timeDifference += 24 * 60 * 60 * 1000;
    }

    const timeDifferenceInHours: number = timeDifference / (1000 * 3600);

    if (timeDifferenceInHours < 8) {
      const newTime: Date = new Date(
        currentTime.getTime() + 8 * 60 * 60 * 1000
      );
      alert(
        `please select time after ${String(newTime.getHours()).padStart(
          2,
          "0"
        )}:${String(newTime.getMinutes()).padStart(2, "0")}`
      );

      setValue(startDate, null);
    } else {
      setValue(startDate, value);
    }
  };

  return (
    <div className="w-full my-2">
      <div className="text-left text-[#024773] font-[300]">Time</div>
      <div className="flex justify-between gap-3">
        <div className="relative bg-white w-1/2 rounded">
          <TimePicker
            name={startDate}
            {...register(startDate)}
            onChange={(value: string) => handleStartDate(value)}
            disabled={isReviewed}
            value={getValues(startDate) || null}
            placeHolder="Start Time"
            inputClassName={`${
              isReviewed ? "bg-[#FAF2F2]" : "bg-white"
            } w-full text-[0.8rem] p-1 rounded outline-none focus:outline-none disabled:opacity-50`}
          />

          <FaRegClock className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400 cursor-pointer" />
        </div>
        <div className="relative bg-white w-1/2 rounded">
          <TimePicker
            name={endDate}
            {...register(endDate)}
            onChange={(value) => {
              setValue(endDate, value);
            }}
            disabled={isReviewed}
            value={getValues(endDate) || null}
            placeHolder="End Time"
            inputClassName={`${
              isReviewed ? "bg-[#FAF2F2]" : "bg-white"
            } w-full text-[0.8rem] p-1 rounded outline-none focus:outline-none disabled:opacity-50`}
          />
          <FaRegClock className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400 cursor-pointer" />
        </div>
      </div>
    </div>
  );
};
export default FormTime;

// import React, { useEffect, useState } from "react";
// import { FaRegClock } from "react-icons/fa6";
// import { TimePicker } from "react-time-picker-typescript"; // Import TimePicker
// import "react-time-picker-typescript/dist/style.css";
// import "../../../src/index.css";
// interface FormTimeProps {
//   register: any;
//   startDate: string | undefined;
//   endDate: string | undefined;
//   isReviewed: boolean;
//   setValue?: any;
//   getValues?: any;
// }
// const FormTime: React.FC<FormTimeProps> = ({
//   register,
//   startDate,
//   endDate,
//   isReviewed,
//   setValue,
//   getValues,
// }) => {
//   const handleStartDate = (value: any) => {
//     setValue(startDate, value.target.value);
//   };

//   const handleEndDate = (value: any) => {
//     setValue(endDate, value.target.value);
//   };

//   return (
//     <div className="w-full my-2">
//       <div className="text-left text-[#024773] font-[300]">Time</div>
//       <div className="flex justify-between gap-3 ">
//         <div className="relative bg-white w-1/2 rounded time-input-wrapper">
//           <input
//             type="time"
//             name={startDate}
//             {...register(startDate)}
//             onChange={(value: any) => handleStartDate(value)}
//             disabled={isReviewed}
//             // value={getValues(startDate) || null} // Ensure value is defined
//             className={`${
//               isReviewed ? "bg-[#FAF2F2]" : "bg-white"
//             } w-full text-[0.8rem] p-1 rounded outline-none focus:outline-none disabled:opacity-50`}
//           />
//         </div>
//         <div className="relative bg-white w-1/2 rounded time-input-wrapper">
//           <input
//             type="time"
//             name={endDate}
//             {...register(endDate)}
//             onChange={(value: any) => handleEndDate(value)}
//             disabled={isReviewed}
//             // value={getValues(endDate) || null} // Ensure value is defined
//             className={`${
//               isReviewed ? "bg-[#FAF2F2]" : "bg-white"
//             } w-full text-[0.8rem] p-1 rounded outline-none focus:outline-none disabled:opacity-50`}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };
// export default FormTime;
