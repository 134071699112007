import React from "react";
import {
  LandingIMG1,
  LandingIMG2,
  LandingIMG3,
  LandingIMG4,
} from "../../Asset";

const LandingPageImages = () => {
  const Images = [LandingIMG1, LandingIMG2, LandingIMG3, LandingIMG4];
  return (
    <div className="w-full flex gap-3 justify-center px-1 md:px-3 ">
      {Images.map((item, index) => (
        <img
          key={index}
          alt="cover"
          src={item}
          className={`${
            index % 2 !== 0 ? "md:mt-22 mt-10" : "-mt-8"
          } transition duration-500 hover:duration-500 ease-in-out hover:scale-75 w-[23%] `}
        />
      ))}
    </div>
  );
};

export default LandingPageImages;
