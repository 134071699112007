import {
  REGISTER_RESEND_OTP_REQUEST,
  REGISTER_RESEND_OTP_SUCCESS,
  REGISTER_RESEND_OTP_FAILURE,
} from "../../../Utils/Constant";

const initialState = {
  data: null,
  loading: false,
  error: null,
};
export const registerRsendOtpReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case REGISTER_RESEND_OTP_REQUEST:
      return {
        ...state,
        data: action.payload,
        loading: true,
        error: null,
      };
    case REGISTER_RESEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case REGISTER_RESEND_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
