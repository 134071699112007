import { call, put } from "redux-saga/effects";
import { PATCH_API, POST_API } from "../../../Services/API/ApiInstance";
import { authApi } from "../../../Utils/Constant";
import {
  verifyRegisterOtpSuccess,
  verifyRegisterOtpFailure,
} from "../../Actions";
import toast from "react-hot-toast";

function* verifyRegisterOtpSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    toast.loading("Verifying OTP ...");
    const res = yield call(PATCH_API, authApi.REGISTER_OTP_VERFICATION, data);
    toast.dismiss();
    toast.success(res.message);
    yield put(verifyRegisterOtpSuccess(res));
  } catch (error: any) {
    toast.dismiss();
    yield put(verifyRegisterOtpFailure(error));
  }
}

export default verifyRegisterOtpSaga;
