import {
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_UP_EMPTY,
} from "../../../Utils/Constant";

const initialState = {
  data: {},
  loading: false,
  error: null,
  info: {},
};

export const signUpReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SIGN_UP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        info: action.payload,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SIGN_UP_EMPTY:
      return {
        ...state,
        data: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
