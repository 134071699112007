import {
  GET_SUB_CATEGORY_REQUEST,
  GET_SUB_CATEGORY_SUCCESS,
  GET_SUB_CATEGORY_FAILURE,
} from "../../../Utils/Constant";

export const getSubCategoryRequest = (id: number) => {
  return {
    type: GET_SUB_CATEGORY_REQUEST,
    payload: id,
  };
};

export const getSubCategorySuccess = (data: object) => {
  return {
    type: GET_SUB_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const getSubCategoryfailure = (error: object) => {
  return {
    type: GET_SUB_CATEGORY_FAILURE,
    payload: error,
  };
};
