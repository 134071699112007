import React, { useEffect, useState } from "react";
import RememberMe from "../InputComponent/RememberMe";
import { dressCodes, foodPreferences } from "../../Utils/dummyStorage/data";
interface FormPreferenceProps {
  setValue: any;
  register: any;
  getValues: any;
  foodPreference: boolean;
  dressCode: boolean;
  setFoodPreference: any;
  setDressCode: any;
  isReviewed: boolean;
}
const FormPreference: React.FC<FormPreferenceProps> = ({
  register,
  setValue,
  getValues,
  foodPreference,
  dressCode,
  setFoodPreference,
  setDressCode,
  isReviewed,
}) => {
  const [pref, setPref] = useState(getValues("foodPreference"));

  const handleChange = (e: any) => {
    setValue("foodPreference", e.target.value);
    setPref(getValues("foodPreference"));
  };

  useEffect(() => {
    setPref(getValues("foodPreference"));
  }, [getValues("foodPreference")]);

  useEffect(() => {
    if (!isReviewed) {
      setFoodPreference(false);
      setDressCode(false);
    }
    if (getValues("foodPreference")) {
      setFoodPreference(true);
    }
    if (getValues("menDressCode") || getValues("womenDressCode")) {
      setDressCode(true);
    }
  }, []);

  useEffect(() => {
    console.log();
  }, [pref]);

  return (
    <div className="h-full w-full flex flex-col items-center bg-white mb-8">
      <div className="w-full flex flex-col justify-center items-center mt-12 bg-white overflow-scroll">
        <div className="w-3/4 my-2">
          <RememberMe
            messageStyle="text-[#024773] text-[0.9rem]"
            label={""}
            checked={getValues("isFoodPreferenceEnabled")}
            onChange={(data) => {
              setFoodPreference(data);
              setValue("isFoodPreferenceEnabled", data);
            }}
            message={"Want to enable Food Preference?"}
            isReviewed={isReviewed}
          />
          {foodPreference && (
            <div className="text-left m-3">
              {foodPreferences.map((item, index) => (
                <div key={index}>
                  {isReviewed && getValues("foodPreference") ? (
                    <input
                      type="radio"
                      name="foodPreference"
                      value={item.lable}
                      checked={pref === item.lable}
                      onChange={(e) => handleChange(e)}
                      disabled={isReviewed}
                      id={`foodPreference${index}`}
                    />
                  ) : (
                    <input
                      type="radio"
                      className="cursor-pointer"
                      name="foodPreference"
                      value={item.lable}
                      checked={pref === item.lable}
                      onChange={(e) => handleChange(e)}
                      id={`foodPreference${index}`}
                    />
                  )}
                  <label
                    htmlFor={`foodPreference${index}`}
                    className="text-[0.8rem] mx-4"
                  >
                    {item.lable}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
        <hr className="w-3/4 mx-3 border-t border-gray-300 my-3" />
        <div className="w-3/4 my-2">
          <RememberMe
            messageStyle="text-[#024773] text-[0.9rem]"
            label={""}
            checked={getValues("isDressCodeEnabled")}
            onChange={(data) => {
              setDressCode(data);
              setValue("isDressCodeEnabled", data);
            }}
            message={"Want to enable Dress Code?"}
            isReviewed={isReviewed}
          />
          {dressCode &&
            dressCodes.map((item, index) => (
              <div key={index}>
                <p className="text-[0.8rem] font-[200] text-[#0074A1] text-left mt-6">
                  {item.lable}
                </p>
                <div className="w-full h-[15vh] border rounded-lg p-1 relative">
                  {isReviewed ? (
                    <textarea
                      className="w-full h-[98%] resize-none active:outline-none focus:outline-none outline-none placeholder:text-[#666666] placeholder:font-[200] text-[0.8rem] pl-2 pt-2"
                      name={`dressCode${index}`}
                      {...register(item.name)}
                      id={`dressCode${index}`}
                      placeholder="Mention your dress code"
                      defaultValue={getValues(item.name)}
                      disabled={isReviewed}
                    />
                  ) : (
                    <textarea
                      className="w-full h-[98%] resize-none active:outline-none focus:outline-none outline-none placeholder:text-[#666666] placeholder:font-[200] text-[0.8rem] pl-2 pt-2"
                      name={`dressCode${index}`}
                      {...register(item.name)}
                      id={`dressCode${index}`}
                      placeholder="Mention your dress code"
                    />
                  )}
                  <p className="absolute bottom-1 text-center w-full text-[#666666] text-[0.8rem] font-[200]">
                    100 characters
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FormPreference;
