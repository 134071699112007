export const landingPageButton = [
  {
    lable: "Log in",
    gredient: true,
    disable: false,
    mainStyle: "w-[10.5rem] shadow-all-sides",
    route: "/login",
  },
  {
    lable: "Create an account",
    gredient: false,
    disable: false,
    mainStyle: "w-[10.5rem] shadow-all-sides",
    route: "/signup",
  },
];
