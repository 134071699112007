import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { PUT_API } from "../../../Services/API/ApiInstance";
import { updateEventSuccess, updateEventFailure } from "../../Actions/Event/UpdateEventActions";

function* updateEventSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(PUT_API, authApi.UPDATE_EVENT, data);
    toast.success(res.message);
    yield put(updateEventSuccess(res.data));
  } catch (error: any) {
    if (error?.response?.data?.statusCode === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    }
    toast.error(error?.response?.data?.message);
    yield put(updateEventFailure(error));
  }
}

export default updateEventSaga;
