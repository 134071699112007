import {
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
} from "../../../Utils/Constant";

export const updateProfileRequest = (data: any) => {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload: data,
  };
};

export const updateProfileSuccess = (data: object) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: data,
  };
};

export const updateProfileFailure = (error: object) => {
  return {
    type: UPDATE_PROFILE_FAILURE,
    payload: error,
  };
};
