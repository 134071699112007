import React, { ChangeEvent } from "react";

interface RememberMeProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  message: string;
  messageStyle?: string;
  mainStyle?: string;
  isReviewed: boolean;
}

const RememberMe: React.FC<RememberMeProps> = ({
  label,
  checked,
  onChange,
  message,
  messageStyle,
  mainStyle,
  isReviewed,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <div className={`flex justify-between my-2 ${mainStyle || "w-full"}`}>
      <p
        className={`text-center ${
          message === "Want to test this invitation?"
            ? "text-[18px] font-medium text-black font-inter"
            : "text-[0.8rem]"
        } ${messageStyle}`}
      >
        {message}
      </p>
      <label className="flex items-center justify-between cursor-pointer">
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          className={`sr-only peer`}
          disabled={isReviewed}
        />
        <div className="relative w-7 h-4 bg-[#abc2d1] peer-focus:outline-none  rounded-full peer dark:bg-[gray-700] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-[#0074A1]"></div>
        {/* <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label}
        </span> */}
      </label>
    </div>
  );
};

export default RememberMe;
