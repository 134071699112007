import {
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
} from "../../../Utils/Constant";

const initialState = {
  data: {},
  loading: false,
  error: null,
};
export const getEventReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case GET_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: action?.payload?.data,
      };
    case "GET_EVENT_RESET":
      return {
        ...state,
        data: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
