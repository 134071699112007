import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { DELETE_API } from "../../../Services/API/ApiInstance";
import { deleteEventSuccess, deleteEventFailure } from "../../Actions/Event/DeleteEventActions";

function* deleteEventSaga(action: any): Generator<any, void, any> {
  const eventId = action.payload;
  try {
    const res = yield call(
      DELETE_API,
      authApi.DELETE_EVENT + eventId,
    );
    yield put(deleteEventSuccess(res.data));
    toast.success("Event deleted successfully");
  } catch (error: any) {
    if (error?.response?.data?.statusCode === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    }
    toast.error(error?.response?.data?.message);
    yield put(deleteEventFailure(error));
  }
}

export default deleteEventSaga;
