import React from "react";
import InputComponent from "../InputComponent/InputComponent";
import Button from "../AuthButton/Button";
import RememberMe from "../InputComponent/RememberMe";
interface LoginContextProps {
  error: any;
  value: any;
  handleMobileChanges: any;
  toggle: any;
  setToggle: any;
  handleSendOtp: any;
}
const LoginContext: React.FC<LoginContextProps> = ({
  error,
  value,
  handleMobileChanges,
  toggle,
  setToggle,
  handleSendOtp,
}) => {
  return (
    <>
      <div className="w-full flex justify-center">
        <div className=" text-[0.9rem] text-center">
          Please confirm your mobile number.
        </div>
      </div>
      <div className="w-full flex justify-center mt-9 ">
        <InputComponent
          lable={""}
          err={error}
          value={value}
          type={"telephone"}
          onChange={(e) => handleMobileChanges(e)}
          placeHolder={"Enter your mobile number"}
          inputStyle={"w-full  appearance-none leading-tight shadow-sm "}
          mainStyle={"w-72 md:w-1/2"}
          errMsg=""
        />
      </div>
      <div className="w-full flex justify-center">
        <RememberMe
          label={""}
          mainStyle="w-72 md:w-1/2"
          checked={toggle}
          onChange={setToggle}
          message={"Remember Me"}
          isReviewed={false}
        />
      </div>
      <div className="w-full flex justify-center mt-8 mb-4">
        <Button
          lable={"Get OTP"}
          gredient={true}
          mainStyle={"w-[16rem]"}
          buttonClick={() => handleSendOtp()}
        />
      </div>
    </>
  );
};

export default LoginContext;
