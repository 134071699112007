import {
  GET_TRANSITION_REQUEST,
  GET_TRANSITION_SUCCESS,
  GET_TRANSITION_FAILURE,
} from "../../../Utils/Constant";

export const getTransitionHistoryRequest = () => {
  return {
    type: GET_TRANSITION_REQUEST,
  };
};

export const getTransitionHistorySuccess = (data: object) => {
  return {
    type: GET_TRANSITION_SUCCESS,
    payload: data,
  };
};

export const getTransitionHistoryFailure = (error: object) => {
  return {
    type: GET_TRANSITION_FAILURE,
    payload: error,
  };
};
