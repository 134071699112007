import React, { useEffect } from "react";
import LandingPageHeader from "../Component/LandingPageComponent/LandingPageHeader";
import LandingPageText from "../Component/LandingPageComponent/LandingPageText";
import LandingPageImages from "../Component/LandingPageComponent/LandingPageImages";
import { deleteUserAccountReset } from "../Redux/Actions";
import { useDispatch } from "react-redux";

const LandingPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(deleteUserAccountReset());
  }, []);
  return (
    <div className="flex flex-col h-screen w-[100vw] overflow-hidden">
      <LandingPageHeader bgColor={"bg-white"} />
      <div className="h-full w-full bg-[#F4E4E4]">
        <div className="md:flex block h-full">
          <div className="md:w-[40vw] w-full flex justify-center">
            <LandingPageText />
          </div>
          <div className="md:w-[60vw] w-full h-full flex justify-center md:items-center items-start">
            <LandingPageImages />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
