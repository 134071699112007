import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API } from "../../../Services/API/ApiInstance";
import {
  saveAndSendInvitationSuccess,
  saveAndSendInvitationFailure,
  inviitationSuccess,
  inviitationFailure,
} from "../../Actions";

function* invitationSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(
      GET_API,
      `${authApi.INVITATION}${data.eventId}/?contactId=${data.contactId}`,
      data
    );
    toast.success(res.message);
    yield put(inviitationSuccess(res.data));
  } catch (error: any) {
    // toast.error(error?.response?.data?.message);
    yield put(inviitationFailure(error));
  }
}

export default invitationSaga;
