import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { DELETE_API } from "../../../Services/API/ApiInstance";
import { deleteUserAccountSuccess, deleteUserAccountFailure } from "../../Actions";

function* deleteUserAccountSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(
      DELETE_API,
      authApi.DELETE_USER_ACCOUNT, data,
    );
    yield put(deleteUserAccountSuccess(res));
  } catch (error: any) {
    if (error?.response?.data?.statusCode === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    }
    toast.error(error?.response?.data?.message || "Failed to delete user account");
    yield put(deleteUserAccountFailure(error));
  }
}

export default deleteUserAccountSaga;
