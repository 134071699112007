import {
  GET_MAIN_CATEGORY_REQUEST,
  GET_MAIN_CATEGORY_SUCCESS,
  GET_MAIN_CATEGORY_FAILURE,
} from "../../../Utils/Constant";

export const getMainCategoryRequest = () => {
  return {
    type: GET_MAIN_CATEGORY_REQUEST,
  };
};

export const getMainCategorySuccess = (data: object) => {
  return {
    type: GET_MAIN_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const getMainCategoryfailure = (error: object) => {
  return {
    type: GET_MAIN_CATEGORY_FAILURE,
    payload: error,
  };
};
