import {
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILURE,
} from "../../../Utils/Constant";

export const postCreateEventRequest = (data: object) => {
  return {
    type: CREATE_EVENT_REQUEST,
    payload: data,
  };
};

export const postCreateEventSuccess = (data: object) => {
  return {
    type: CREATE_EVENT_SUCCESS,
    payload: data,
  };
};

export const postCreateEventfailure = (error: object) => {
  return {
    type: CREATE_EVENT_FAILURE,
    payload: error,
  };
};
