import {
  INVITEE_PAGE_REQUEST,
  INVITEE_PAGE_SUCCESS,
  INVITEE_PAGE_FAILURE,
} from "../../../Utils/Constant";

export const inviitationRequest = (data: object) => {
  return {
    type: INVITEE_PAGE_REQUEST,
    payload: data,
  };
};

export const inviitationSuccess = (data: object) => {
  return {
    type: INVITEE_PAGE_SUCCESS,
    payload: data,
  };
};

export const inviitationFailure = (error: object) => {
  return {
    type: INVITEE_PAGE_FAILURE,
    payload: error,
  };
};
