import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API, POST_API } from "../../../Services/API/ApiInstance";
import { updateProfileFailure, updateProfileSuccess } from "../../Actions";

function* updateProfileSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(POST_API, authApi.UPDATE_PROFILE, data);
    yield put(updateProfileSuccess(res.data));
  } catch (error: any) {
    if (error?.response?.data?.statusCode === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    }
    toast.error(error?.response?.data?.message);
    yield put(updateProfileFailure(error));
  }
}

export default updateProfileSaga;
