import {
  INVITEE_RESPONSE_PAGE_REQUEST,
  INVITEE_RESPONSE_PAGE_SUCCESS,
  INVITEE_RESPONSE_PAGE_FAILURE,
} from "../../../Utils/Constant";

const initialState = {
  data: {},
  loading: false,
  error: null,
};
export const invitationResponseReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INVITEE_RESPONSE_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case INVITEE_RESPONSE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case INVITEE_RESPONSE_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
