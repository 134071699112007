import React, { ReactChild } from "react";
interface HomeCardProps {
  children?: ReactChild;
  mainStyle?: string;
}
const HomeCard: React.FC<HomeCardProps> = ({ children, mainStyle }) => {
  return (
    <div className={`md:w-3/4 w-[90%] bg-white shadow-md  h-[85%] ${mainStyle}`}>
      {children}
    </div>
  );
};

export default HomeCard;
