import React, { useEffect, useState } from "react";
import LandingPageHeader from "../../Component/LandingPageComponent/LandingPageHeader";
import AuthCard from "../../Component/AuthCard/AuthCard";
import LoginBg from "../../Asset/Svg/LoginBg.svg";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import {
  getProfileInfoRequest,
  makeSignUpEmpty,
  registerResendOtprequest,
  signUprequest,
  verifyRegisterOtpRequest,
} from "../../Redux/Actions";
import SignUpContent from "../../Component/AuthCard/SignUpContent";
import OTPContent from "../../Component/AuthCard/OTPContent";

interface SignUpPageProps {
  signUp: any;
  registrVerifyOtp: any;
  resendRegistrOtp: any;
}
const SignUpPage: React.FC<SignUpPageProps> = ({
  signUp,
  resendRegistrOtp,
  registrVerifyOtp,
}) => {
  const [header, setHeader] = useState("Create an account");
  const [otpSent, setOtpSent] = useState(false);
  const [err, setErr] = useState(false);
  const [authOTP, setAuthOTP] = useState("");
  const [otpErr, setOtpErr] = useState(false);
  const [country, setCountry] = useState<any>({
    code: "+91",
    name: "India",
  });
  const [mobileNumber, setMobileNumber] = useState("");
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (signUp?.data?.statusCode === 200 && !err) {
      setOtpSent(true);
      setHeader("Verify your phone number");
      setAuthOTP(signUp?.data?.data);
    }

    if (resendRegistrOtp?.data?.data) {
      setAuthOTP(resendRegistrOtp?.data?.data);
    }
  }, [err, resendRegistrOtp, signUp]);

  useEffect(() => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      navigate("/home");
    }
  }, []);
  
  const handleBack = () => {
    dispatch(makeSignUpEmpty());
    setOtpSent(false);
    setHeader("Create an account");
    if (header === "Create an account") {
      navigate("/home");
    }
  };

  const handleSignUp = () => {
    if (country && mobileNumber && userName) {
      setErr(false);
      dispatch(
        signUprequest({
          fullName: userName,
          countryName: country.name,
          countryCode: country.code,
          mobileNumber: mobileNumber,
        })
      );
    } else {
      setErr(true);
    }
  };

  const debouncedHandleSendOtp: () => void = debounce(handleSignUp, 2000);

  const handleClick = () => {
    debouncedHandleSendOtp();
  };

  const handleOTPValidation = (data: string) => {
    if (!data || data.length !== 4) {
      setOtpErr(true);
      return;
    } else {
      dispatch(
        verifyRegisterOtpRequest({ mobileNumber: mobileNumber, otp: data })
      );
    }
  };

  useEffect(() => {
    setOtpErr(false);
    if (registrVerifyOtp?.data?.statusCode === 200) {
      sessionStorage.setItem(
        "token",
        registrVerifyOtp?.info?.data?.accessToken
      );
      setOtpErr(false);
      navigate("/");
    } else {
      if (registrVerifyOtp?.data) {
        setTimeout(() => {
          setOtpErr(true);
        }, 600);
      }
    }
  }, [registrVerifyOtp]);

  useEffect(() => {
    return () => {
      dispatch(makeSignUpEmpty());
    };
  }, []);

  const handleResendOtp = () => {
    setOtpErr(false);
    setAuthOTP("");
    dispatch(registerResendOtprequest({ mobileNumber }));
    dispatch(makeSignUpEmpty());
  };

  return (
    <div
      className="w-full bg-cover bg-center h-screen overflow-clip"
      style={{ backgroundImage: `url(${LoginBg})` }}
    >
      <LandingPageHeader bgColor={"bg-[#F4E4E4]"} />
      <AuthCard
        handleBack={handleBack}
        headerLable={header}
        backArrow={false}
        closeIcon={!otpSent}
        onClose={handleBack}
      >
        <>
          {!otpSent ? (
            <SignUpContent
              err={err}
              setMobileNumber={(data) => setMobileNumber(data)}
              setUserName={(data) => setUserName(data)}
              setCountry={(data: any) => setCountry(data)}
              country={country}
              mobileNumber={mobileNumber}
              handleSignUp={handleClick}
            />
          ) : (
            <OTPContent
              title={`We’ve sent an SMS with an activation code to your phone ${country.code} ${mobileNumber}`}
              mobile={signUp?.info?.mobileNumber || ""}
              message={"Send code again"}
              count={30}
              errMSg={
                "Code is incorrect. Check the code again and enter it correctly."
              }
              err={otpErr}
              handleOTPValidation={(data) => {
                handleOTPValidation(data);
              }}
              handleResendOtp={handleResendOtp}
              handleOtpError={() => setOtpErr(false)}
            />
          )}
        </>
      </AuthCard>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    signUp: state.signUpReducer,
    registrVerifyOtp: state.verifyRegisterOtpReducer,
    resendRegistrOtp: state.registerRsendOtpReducer,
  };
};

export default connect(mapStateToProps)(SignUpPage);
