import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { TiThMenu } from "react-icons/ti";
import { PiUserDuotone } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { RiCloseLine } from "react-icons/ri";
import {
  Calender,
  Credit,
  Discount,
  Discovery,
  Gallery,
  Logout,
  profile,
  Reports,
  Settings,
} from "../../Asset";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";
import ConfirmationModal from "../../modals/ConfirmationModal";

interface NavbarProps {
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  user_name: string;
}

const LOGOUT_CONFIRMATION_MESSAGE = "Are you sure you want to log out?";

const Navbar: React.FC<NavbarProps> = ({ user_name }) => {
  const [menu, setMenu] = useState(false);
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        event.target !== document.querySelector(".menu-icon")
      ) {
        setMenu(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleLinkClick = () => {
    setMenu(false);
    setShowSettingsMenu(false);
  };

  const handleSettingsClick = () => {
    setShowSettingsMenu((prev) => !prev);
  };

  const handleLogOut = () => {
    setShowLogoutConfirmation(true);
  };
  const confirmLogOut = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    navigate("/home");
  };

  return (
    <div className="fixed z-10 rounded-lg w-full  h-12 md:h-14 ">
      <div className="flex justify-between items-center px-2 md:px-10 my-auto h-full">
        <div>
          {!menu ? (
            <TiThMenu
              className="block md:hidden mx-3 cursor-pointer menu-icon"
              onClick={() => setMenu((prev) => !prev)}
            />
          ) : (
            <RiCloseLine
              className="block md:hidden mx-3 cursor-pointer menu-icon text-[1.4rem]"
              onClick={() => setMenu((prev) => !prev)}
            />
          )}
        </div>
        {menu && (
          <div
            ref={menuRef}
            className="block w-full md:hidden absolute top-10 left-0 bg-[#024773] h-screen"
          >
            {menuContent.map((item, index) => (
              <div key={index}>
                {item.name === "Settings" ? (
                  <>
                    <div
                      onClick={handleSettingsClick}
                      className="flex items-center gap-2 py-2 px-6 justify-between border-b bg-[#024773] cursor-pointer"
                    >
                      <div className="flex gap-2 ">
                        <img
                          src={item.icon}
                          className={`h-5 ${item?.imageStyle}`}
                          alt=""
                        />
                        <p className="text-white">{item.name}</p>
                      </div>
                      <div>
                        <FaAngleDown className="text-[1.2rem] text-center text-white mt-1" />
                      </div>
                    </div>
                    {showSettingsMenu &&
                      item.option &&
                      item.option.map((subItem, subIndex) => (
                        <Link
                          key={subIndex}
                          to={subItem.path}
                          className="w-full"
                          onClick={handleLinkClick}
                        >
                          <div className="flex items-center gap-2 py-2 px-12 border-b bg-[#024773]">
                            <img
                              src={subItem.icon}
                              className={`h-5 ${subItem?.imageStyle}`}
                              alt=""
                            />
                            <p className="text-white">{subItem.name}</p>
                          </div>
                        </Link>
                      ))}
                    {showSettingsMenu && (
                      <div
                        className="w-full cursor-pointer"
                        onClick={handleLogOut}
                      >
                        <div
                          className={`flex items-center gap-2 py-2  border-b bg-[#024773]`}
                        >
                          <img src={Logout} alt="" className={`h-5 pl-12`} />
                          <p className="text-white">Log out</p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <Link
                    to={item.path}
                    className="w-full"
                    onClick={handleLinkClick}
                  >
                    <div
                      className={`flex items-center gap-2 py-2 px-6 border-b bg-[#024773]`}
                    >
                      <img
                        src={item.icon}
                        className={`h-5 ${item?.imageStyle}`}
                        alt=""
                      />
                      <p className="text-white">{item.name}</p>
                    </div>
                  </Link>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="flex gap-3 items-center">
          <div className="flex items-center gap-3">
            <PiUserDuotone className="h-6 w-6" />
            <p className="flex items-center text-[0.8rem]">
              {user_name} <IoIosArrowDown />
            </p>
          </div>
        </div>
      </div>
      {showLogoutConfirmation && (
        <ConfirmationModal
          onCancel={() => setShowLogoutConfirmation(false)}
          onConfirm={confirmLogOut}
          message={LOGOUT_CONFIRMATION_MESSAGE}
        />
      )}
    </div>
  );
};

export default Navbar;

const menuContent = [
  {
    name: "Events",
    icon: Calender,
    path: "/",
  },
  {
    name: "Report",
    icon: Reports,
    path: "/orders",
  },
  {
    name: "Gallery",
    icon: Gallery,
    path: "/gallery",
    imageStyle: "h-[1rem]",
  },
  {
    name: "Settings",
    icon: Settings,
    path: "/settings",
    option: [
      {
        name: "My Profile",
        icon: profile,
        path: "/profile",
      },
      {
        name: "Buy Credit",
        icon: Discount,
        path: "/buycredit",
      },
      {
        name: "Vender",
        icon: Discovery,
        path: "/vender",
      },
      {
        name: "My Credit",
        icon: Credit,
        path: "/mycredit",
        imageStyle: "h-4",
      },
    ],
  },
];
