import {
  GET_EVENT_ID_REQUEST,
  GET_EVENT_ID_SUCCESS,
  GET_EVENT_ID_FAILURE,
  GET_EVENT_ID_RESET,
} from "../../../Utils/Constant";

export const getEventIDRequest = (id: number) => {
  return {
    type: GET_EVENT_ID_REQUEST,
    payload: id,
  };
};

export const getEventIDSuccess = (data: object) => {
  return {
    type: GET_EVENT_ID_SUCCESS,
    payload: data,
  };
};

export const getEventIDFailure = (error: object) => {
  return {
    type: GET_EVENT_ID_FAILURE,
    payload: error,
  };
};

export const getEventIDReset = () => ({
    type: GET_EVENT_ID_RESET,
});
